.comunity-details-page .main-container {
  width: 750px;
  margin: 32px auto 0;
}
.comunity-details-page .no-data-box {
  text-align: center;
  margin: 165px auto;
}
.comunity-details-page .no-data-box img {
  width: 120px;
  height: 120px;
}
.comunity-details-page .no-data-box .tips {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-top: 26px;
}
