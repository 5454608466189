.user-is-teacher {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 0;
  white-space: nowrap;
}

.user-is-teacher.STAR span {
  color: #FE6D13;
  background-color: #FEF3E2;
}

.user-is-teacher.small span {
  padding: 0 0.053333rem 0 0.053333rem;
  line-height: 0.373333rem;
  font-size: 0.266667rem;
}

.user-is-teacher.small img {
  width: 0.266667rem;
  height: 0.266667rem;
}

.user-is-teacher img {
  width: 0.32rem;
  height: 0.32rem;
  vertical-align: middle;
  margin-right: 0.106667rem;
  margin-top: -0.053333rem;
}

.user-is-teacher span {
  padding: 0.053333rem 0.106667rem 0.053333rem 0.106667rem;
  background: #FFF2E9;
  -webkit-border-radius: 1.333333rem;
  border-radius: 1.333333rem;
  left: 0.213333rem;
  text-align: right;
  font-size: 0.266667rem;
  font-weight: 400;
  color: #FE6346;
  line-height: 0.373333rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.teacherLogo {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.teacherLogo::before {
  content: '';
  display: block;
  width: 125%;
  height: 125%;
  background: url('https://img.qlchat.com/qlLive/activity/image/825PC56F-CB3V-9DAB-1686808450786-1Q1X2S9QBTAZ.png');
  background-position-x: center;
  background-position-y: center;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: -12.5%;
  top: -12.5%;
  pointer-events: none;
}

.teacherLogo.small::before {
  display: none;
}

.teacherLogo.small::after {
  width: 40%;
  height: 40%;
}

.teacherLogo::after {
  content: '';
  display: block;
  width: 35%;
  height: 35%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url('https://img.qlchat.com/qlLive/activity/image/DRA2TZZT-IDZL-NTZW-1686808456008-Z6MILF4KYVHS.png') center center no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.teacherLogo.STAR::before {
  background: url('https://img.qlchat.com/qlLive/activity/image/KE7USI4V-NS27-62TY-1686808453418-E2E66RSYF4DV.png');
  background-position-x: center;
  background-position-y: center;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.teacherLogo.STAR::after {
  background: url('https://img.qlchat.com/qlLive/activity/image/RDJL3UG6-NN3I-TPNI-1686808458632-ZZ2X9O2FNT7F.png') center center no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.teacherLogo.holidayIcon::before {
  background: var(--before-bg-url) center center no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.teacherLogo.holidayIcon::after {
  display: none;
}