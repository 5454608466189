.excellent-teacher-container {
  margin-top: 32px !important;
  height: 446px;
  position: relative;
}
.excellent-teacher-container .excellent-teacher-swiper-prev {
  position: absolute;
  top: 376px;
  left: 64px;
  z-index: 2;
}
.excellent-teacher-container .excellent-teacher-swiper-prev .to-left {
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
  font-size: 24px;
  color: #CCCCCC;
}
.excellent-teacher-container .excellent-teacher-swiper-split {
  position: absolute;
  top: 380px;
  left: 115px;
  font-size: 12px;
  border-left: 1px solid #F3F3F3;
  height: 16px;
  z-index: 2;
}
.excellent-teacher-container .excellent-teacher-swiper-next {
  position: absolute;
  top: 376px;
  left: 142px;
  z-index: 2;
}
.excellent-teacher-container .excellent-teacher-swiper-next > i {
  font-size: 24px;
  color: #CCCCCC;
}
.excellent-teacher-container .excellent-teacher-swiper {
  width: 100%;
  height: 446px;
  background: rgba(255, 255, 255, 0);
  -webkit-border-radius: 24px 24px 24px 24px;
          border-radius: 24px 24px 24px 24px;
  -webkit-box-shadow: 0px 16px 112px 0px rgba(255, 222, 212, 0.42);
          box-shadow: 0px 16px 112px 0px rgba(255, 222, 212, 0.42);
}
.excellent-teacher-container .excellent-teacher-swiper .excellent-teacher-swiper-slide {
  width: 100%;
  height: 446px;
  -webkit-border-radius: 24px 24px 24px 24px;
          border-radius: 24px 24px 24px 24px;
}
.excellent-teacher-container .excellent-teacher-swiper .excellent-teacher-swiper-slide .excellent-teacher-swiper-item {
  width: 100%;
  height: 446px;
}
