.idea-item-like-list-mobile {
  margin-bottom: 0.426667rem;
}

.idea-item-like-list-mobile .like-list-contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 0.426667rem;
  border-bottom: 0.013333rem solid #EEEEEE;
}

.idea-item-like-list-mobile .like-list-contain .like-list-icon {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-right: 0.186667rem;
}

.idea-item-like-list-mobile .like-list-contain .like-header-list {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.idea-item-like-list-mobile .like-list-contain .like-header-list .like-header-img {
  width: 0.853333rem;
  height: 0.853333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 0.013333rem solid #DDDDDD;
  overflow: hidden;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.idea-item-like-list-mobile .like-list-contain .like-header-list .like-header-img img {
  width: 0.853333rem;
  height: 0.853333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.idea-item-like-list-mobile .like-list-contain .like-header-list .like-header-img:not(:first-child) {
  margin-left: -0.213333rem;
}

.idea-item-like-list-mobile .like-list-contain .like-number {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.44rem;
  margin-left: 0.32rem;
}