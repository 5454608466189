.idea-item-user-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.idea-item-user-info .info-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.idea-item-user-info .info-left .info-header-img {
  width: 48px;
  height: 48px;
  overflow: hidden;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  border: 1px solid #ddd;
}
.idea-item-user-info .info-left .info-name-other {
  margin-left: 12px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.idea-item-user-info .info-left .info-name-other .name-wrap {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}
.idea-item-user-info .info-left .info-name-other .time-number {
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
