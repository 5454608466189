.comment-list-box {
  margin-top: 0px;
}
.comment-list-box .comment-contain .middle-comment-entry-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px;
}
.comment-list-box .comment-contain .middle-comment-entry-wrap .comment-icon {
  -webkit-box-flex: 0;
  -webkit-flex: none;
     -moz-box-flex: 0;
      -ms-flex: none;
          flex: none;
  width: 24px;
  height: auto;
  margin-right: 16px;
}
.comment-list-box .comment-contain .middle-comment-entry-wrap .input-box {
  width: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 42px;
  background: #F7F7F7;
  -webkit-border-radius: 32px;
          border-radius: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 24px;
  font-size: 14px;
  color: #999;
  cursor: pointer;
}
.comment-list-box .list-box .comment-list-contain {
  padding-left: 40px;
}
.comment-list-box .list-box .comment-list-contain .comment-item {
  margin-bottom: 34px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-left: 0;
  margin-right: 0;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-header-img {
  width: 42px;
  height: 42px;
  margin-right: 16px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
  border: 1px solid #DDDDDD;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  overflow: hidden;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 42px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-name {
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  line-height: 33px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-name .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 230px;
  display: inline-block;
  margin-right: 8px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .like-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .like-box.active {
  color: #F5333F;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .like-box .iconfont {
  font-size: 20px;
  margin-left: 8px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .user-name-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .user-name-btn .name {
  width: 264px;
  font-size: 26px;
  font-weight: 700;
  color: #333333;
  line-height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .user-name-btn .btn {
  font-size: 24px;
  font-weight: 400;
  color: #BBBBBB;
  line-height: 33px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-wrap .spread-btn {
  font-size: 14px;
  color: #F5333F;
  cursor: pointer;
  line-height: 33px;
  margin-top: 8px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-message {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-message .GRID_1_4 {
  width: 120px;
  height: 120px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-message .GRID_1_7 {
  width: 40px;
  height: 40px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn {
  margin-top: 13px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .time {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-right: 24px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .reply-btn,
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .reply-box-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  color: #999;
  cursor: pointer;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .reply-box-btn .iconfont {
  font-size: 24px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn > .icongengduo1 {
  font-size: 32px;
  color: #999;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box {
  padding-left: 58px;
  margin-top: 16px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain {
  background: #F7F7F7;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  padding: 24px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain .comment-detail-item-wrap:not(:last-child) {
  margin-bottom: 16px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain .comment-header-img {
  width: 24px;
  height: 24px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain .top-wrap {
  height: 24px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain .comment-message {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain .user-reply-ctn .reply-text {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin: 0 8px;
}
.comment-list-box .list-box .comment-list-contain .comment-item .repli-box .repli-contain .user-reply-ctn .name {
  font-weight: bold;
}
.comment-list-box .list-box .more-btn {
  margin-top: 34px;
  font-size: 14px;
  font-weight: 400;
  color: #F5333F;
  line-height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 98px;
}
.comment-list-box .no-data-box {
  text-align: center;
  margin: 24px 0 64px;
}
.comment-list-box .no-data-box .no-data-img {
  width: 120px;
  height: 120px;
}
.comment-list-box .no-data-box .no-data-tips {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
