.category-second-screen .famous-banner-box-swiper.on-pagination .swiper-pagination {
  display: none;
}

.category-second-screen .famous-banner-box-swiper .swiper-slide {
  padding: 0 0.426667rem;
}

.category-second-screen .famous-banner-box-swiper .swiper-pagination {
  bottom: 0.773333rem;
}

.category-second-screen .famous-banner-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.213333rem;
  height: 0.08rem;
  background: rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 1.08rem;
  border-radius: 1.08rem;
  opacity: 1;
}

.category-second-screen .famous-banner-box-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #F5333F;
}

.category-second-screen .famous-banner-box-swiper .banner-card {
  width: 100%;
  height: 6.56rem;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  padding: 0.64rem 0.426667rem;
}

.category-second-screen .famous-banner-box-swiper .banner-card .banner-card-name {
  font-weight: 500;
  font-size: 0.506667rem;
  color: #1A1A1A;
  line-height: 0.853333rem;
}

.category-second-screen .famous-banner-box-swiper .banner-card .banner-card-title {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #1A1A1A;
  line-height: 0.586667rem;
  position: relative;
  padding-left: 0.16rem;
}

.category-second-screen .famous-banner-box-swiper .banner-card .banner-card-title::before {
  content: "";
  position: absolute;
  width: 0.053333rem;
  height: 0.373333rem;
  background: #F5333F;
  -webkit-border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
  border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.category-second-screen .famous-banner-box-swiper .banner-card .banner-card-desc {
  margin-top: 0.426667rem;
}

.category-second-screen .famous-banner-box-swiper .banner-card .banner-card-desc .desc-item {
  width: 4.026667rem;
  font-weight: 400;
  font-size: 0.32rem;
  color: #808080;
  line-height: 0.48rem;
  text-align: left;
  padding-left: 0.16rem;
  position: relative;
}

.category-second-screen .famous-banner-box-swiper .banner-card .banner-card-desc .desc-item::after {
  content: "";
  width: 0.053333rem;
  height: 0.053333rem;
  background: #808080;
  position: absolute;
  left: 0;
  top: 0.213333rem;
}

.category-second-screen .famous-banner-box-swiper .banner-card-bottom {
  background: transparent;
  height: 1.173333rem;
}