.tab-switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.173333rem;
  padding: 0 0.213333rem;
  background: #ffffff;
  position: relative;
}

.tab-switch .tab-switch-item {
  font-size: 0.373333rem;
  color: #666666;
  padding: 0 0.213333rem;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  z-index: 2;
}

.tab-switch .tab-switch-item.active {
  font-size: 0.426667rem;
  font-weight: bold;
  color: #1a1a1a;
  position: relative;
}

.tab-switch .tab-switch-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0.533333rem;
  height: 0.08rem;
  background: #F5333F;
  -webkit-border-radius: 0.04rem;
  border-radius: 0.04rem;
}