.student-story-box .student-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.student-story-box .student-list .student-item {
  width: 282px;
  height: 241px;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  overflow: hidden;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/XKESIE3A-AKH4-M3WT-1653465634382-A8M6CGL8H53W.png?x-oss-process=image/format,webp");
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: 32px;
  padding: 48px 24px 24px 24px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
}
.student-story-box .student-list .student-item:hover {
  -webkit-transform: translateY(-20px);
     -moz-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
}
.student-story-box .student-list .student-item:hover .crrow {
  opacity: 1;
}
.student-story-box .student-list .student-item .crrow {
  width: 24px;
  height: 24px;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0px 8px 15px 0px rgba(245, 51, 63, 0.2);
          box-shadow: 0px 8px 15px 0px rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  right: 24px;
  bottom: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  opacity: 0;
}
.student-story-box .student-list .student-item .crrow i {
  color: #fff;
  font-size: 12px;
  margin-left: 2px;
}
.student-story-box .student-list .student-item .message {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 27px;
  height: 81px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.student-story-box .student-list .student-item .user-message {
  border-top: 1px solid #EEEEEE;
  margin-top: 24px;
  padding-top: 23px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.student-story-box .student-list .student-item .user-message .user-header-img {
  width: 40px;
  height: 40px;
  border: none;
  margin-right: 12px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
}
.student-story-box .student-list .student-item .user-message .user-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.student-story-box .student-list .student-item .user-message .user-name .name {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.student-story-box .student-list .student-item .user-message .user-name .work {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
}
.student-story-box .more-community-btn {
  width: 192px;
  height: 48px;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(245, 51, 63, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 24px;
          border-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  margin: 0px auto 0;
}
