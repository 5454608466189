a[title='站长统计'] {
  display: none;
}

.comunity-list-page-mobile {
  background-color: #fff;
  padding-top: 1.493333rem;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
}

.comunity-list-page-mobile .main-container .community-list-contain .list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.426667rem 0.4rem 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comunity-list-page-mobile .main-container .community-list-contain .list .clockin-box {
  margin-bottom: 0.426667rem;
}

.comunity-list-page-mobile .main-container .community-list-contain .list-btn {
  width: 3.96rem;
  height: 0.853333rem;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0 0.133333rem 0.266667rem 0 rgba(245, 51, 63, 0.2);
  box-shadow: 0 0.133333rem 0.266667rem 0 rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  font-size: 0.346667rem;
  font-weight: 400;
  color: #FFFFFF;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0.453333rem;
}

.comunity-list-page-mobile .main-container .no-data-box {
  margin-top: 1.706667rem;
}

.comunity-list-page-mobile .main-container .no-data-box img {
  width: 3.2rem;
  height: 3.2rem;
  margin: 0 auto;
  display: block;
}

.comunity-list-page-mobile .main-container .no-data-box .tips {
  text-align: center;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.533333rem;
  margin-top: 0.213333rem;
  margin-bottom: 0.853333rem;
}