.default-img.header {
  background: #F7F7F7 url('https://img.qlchat.com/qlLive/activity/image/1K4EBX4B-UM3G-JIBJ-1687255132986-GKMIVEJFR3H7.png');
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100%;
  background-position: center;
}

.default-img.thumbnail {
  background-color: #F7F7F7;
  background-repeat: no-repeat;
  -webkit-background-size: 1.066667rem 1.066667rem;
  background-size: 1.066667rem 1.066667rem;
  background-position: center;
}