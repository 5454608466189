.p-course-intro {
  padding: 0 20px;
  line-height: 1.4;
}
.p-course-intro img {
  width: 100%;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}
.p-course-intro .p-intro-section .p-intro-section-title {
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
}
.p-course-intro.channel .desc-content .with-margin {
  margin-top: 20px;
}
.p-course-intro.channel .desc-content .desc-text {
  color: #333;
  margin-top: 18px;
  font-size: 16px;
}
.p-course-intro.topic {
  padding-top: 20px;
}
.p-course-intro.topic pre {
  margin: 8px 0;
}
.p-course-intro.topic .speaker {
  white-space: pre-wrap;
  word-break: break-word;
}
.p-course-intro.topic .speaker-info {
  color: #999;
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 30px;
}
