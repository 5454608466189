.home-brand-info-wrap .brand-tab-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #EEEEEE;
  -webkit-border-radius: 12px;
          border-radius: 12px;
  margin-top: 48px;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item + .brand-tab-list-item {
  border-left: 1px solid #EEEEEE;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item {
  width: 400px;
  height: 72px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active1 {
  background: url(https://img.qlchat.com/qlLive/activity/image/GX9PNWG7-BFP3-28HH-1710156597370-MKU8EOWJ44XD.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active1 .tab-title,
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active1 .tab-desc {
  color: #FFF;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active2 {
  background: url(https://img.qlchat.com/qlLive/activity/image/IL34P7RH-GTKG-WX8E-1708670124263-VC3DVKM8IRYL.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active2 .tab-title,
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active2 .tab-desc {
  color: #FFF;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active3 {
  background: url(https://img.qlchat.com/qlLive/activity/image/PWMQZF4I-JVYK-65DY-1710156727341-RAY9YHXQV3AQ.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active3 .tab-title,
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item.active3 .tab-desc {
  color: #FFF;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item .tab-title {
  font-weight: 600;
  font-size: 20px;
  color: #1A1A1A;
  line-height: 30px;
}
.home-brand-info-wrap .brand-tab-list .brand-tab-list-item .tab-desc {
  font-weight: 400;
  font-size: 16px;
  color: #4D4D4D;
  line-height: 24px;
}
.home-brand-info-wrap .brand-map {
  width: 1200px;
  height: 820px;
  padding: 18px;
  margin-top: 32px;
  -webkit-box-shadow: 0px 9px 18px 10px rgba(252, 222, 212, 0.42);
          box-shadow: 0px 9px 18px 10px rgba(252, 222, 212, 0.42);
  background: -webkit-linear-gradient(315deg, rgba(255, 243, 234, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: -moz-linear-gradient(315deg, rgba(255, 243, 234, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(135deg, rgba(255, 243, 234, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  border: 4px solid rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 28px;
          border-radius: 28px;
}
.home-brand-info-wrap .brand-map img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-brand-info-wrap .user-comment-container {
  width: 1200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 24px;
}
.home-brand-info-wrap .user-comment-container .user-comment-item {
  width: 368px;
  height: 252px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(252, 222, 212, 0.42);
          box-shadow: 0px 8px 24px 0px rgba(252, 222, 212, 0.42);
  -webkit-border-radius: 24px 24px 24px 0;
          border-radius: 24px 24px 24px 0;
  padding: 24px;
  margin-top: 24px;
}
.home-brand-info-wrap .user-comment-container .user-comment-item .user-comment-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.home-brand-info-wrap .user-comment-container .user-comment-item .user-comment-info .user-avatar {
  width: 56px;
  height: 56px;
}
.home-brand-info-wrap .user-comment-container .user-comment-item .user-comment-info .user-info {
  margin-left: 16px;
}
.home-brand-info-wrap .user-comment-container .user-comment-item .user-comment-info .user-info .user-name {
  font-weight: 400;
  font-size: 16px;
  color: #4D4D4D;
  line-height: 24px;
  text-align: left;
  margin-bottom: 6px;
}
.home-brand-info-wrap .user-comment-container .user-comment-item .user-comment-info .user-info .user-tag {
  background: #F34242;
  -webkit-border-radius: 12px 12px 12px 0px;
          border-radius: 12px 12px 12px 0px;
  border: 1px solid #FEE2E4;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 24px;
  text-align: left;
  padding: 0 8px;
  display: inline-block;
}
.home-brand-info-wrap .user-comment-container .user-comment-item .user-comment-content {
  margin-top: 18px;
  font-weight: 400;
  font-size: 14px;
  color: #4D4D4D;
  line-height: 22px;
  text-align: left;
}
.home-brand-info-wrap .brandInfo-excellent-teacher-swiper {
  width: 100%;
}
.home-brand-info-wrap .brandInfo-excellent-teacher-swiper .brandInfo-excellent-teacher-swiper-slide {
  width: 168px;
  height: 360px;
}
.home-brand-info-wrap .brandInfo-excellent-teacher-swiper .brandInfo-excellent-teacher-swiper-slide .brandInfo-excellent-teacher-swiper-item {
  width: 168px;
  height: 360px;
}
.home-brand-info-wrap .teacher-container {
  position: relative;
  z-index: 1;
}
.home-brand-info-wrap .teacher-container .bg1 {
  width: 553px;
  height: 553px;
  position: absolute;
  top: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
}
.home-brand-info-wrap .teacher-container .criterion {
  width: 320px;
  font-weight: 400;
  font-size: 16px;
  color: #3D3D3D;
  line-height: 24px;
  padding: 8px 0;
  background: #FFFFFF;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 16px 48px 0px rgba(252, 212, 215, 0.52);
          box-shadow: 0px 16px 48px 0px rgba(252, 212, 215, 0.52);
  -webkit-border-radius: 8px 8px 8px 8px;
          border-radius: 8px 8px 8px 8px;
}
.home-brand-info-wrap .teacher-container .criterion .icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.home-brand-info-wrap .teacher-container .criterion.style1 {
  position: absolute;
  top: 173px;
  left: 128px;
}
.home-brand-info-wrap .teacher-container .criterion.style2 {
  position: absolute;
  top: 245px;
  left: 232px;
}
.home-brand-info-wrap .teacher-container .criterion.style3 {
  position: absolute;
  top: 101px;
  left: 50%;
  -webkit-transform: translate(-50%);
     -moz-transform: translate(-50%);
          transform: translate(-50%);
}
.home-brand-info-wrap .teacher-container .criterion.style4 {
  position: absolute;
  top: 173px;
  right: 128px;
}
.home-brand-info-wrap .teacher-container .criterion.style5 {
  position: absolute;
  top: 245px;
  right: 232px;
}
.home-brand-info-wrap .teacher-container .bg2 {
  width: 1024px;
  height: 256px;
  position: absolute;
  top: 624px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
}
.home-brand-info-wrap .brandInfo-carousel-wrapper {
  position: relative;
  top: 335px;
  z-index: 2;
  height: 400px;
  -webkit-perspective: 400px;
     -moz-perspective: 400px;
          perspective: 400px;
  -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: scale(0.55);
     -moz-transform: scale(0.55);
          transform: scale(0.55);
}
.home-brand-info-wrap .brandInfo-carousel-wrapper .brandInfo-carousel-item {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 168px;
  height: 356px;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out, -moz-transform 1s ease-in-out;
}
.home-brand-info-wrap .brandInfo-carousel-wrapper .brandInfo-carousel-item > img {
  width: 100%;
}
