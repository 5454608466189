.p-layout-empty {
  padding-top: 104px;
  text-align: center;
  line-height: 1.8;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.p-layout-empty h2 {
  font-size: 40px;
  color: #2b3940;
}
.p-layout-empty p {
  color: rgba(43, 57, 64, 0.65);
  font-size: 14px;
}
.p-layout-empty p a {
  color: #f73657;
  font-weight: normal;
}
.p-layout-empty > div {
  padding-bottom: 200px;
  background: url('https://img.qlchat.com/qlLive/activity/image/P2HXVWOU-6E7W-9LTN-1609731533225-COKMU81C8QMP.png?x-oss-process=image/format,webp') no-repeat bottom center / 320px auto;
}
