.activity-tab-container {
  margin-top: 48px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.activity-tab-container .activity-tab-list {
  margin-bottom: 32px;
  height: 52px;
  background: #F8F8FB;
  -webkit-border-radius: 141px 141px 141px 141px;
          border-radius: 141px 141px 141px 141px;
  font-weight: 600;
  font-size: 16px;
  color: #1A1A1A;
  line-height: 24px;
  text-align: center;
  padding: 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.activity-tab-container .activity-tab-list .activity-tab-item {
  height: 48px;
  padding: 8px 32px;
}
.activity-tab-container .activity-tab-list .activity-tab-item.active {
  background: #F5333F;
  color: #FFFFFF;
  -webkit-border-radius: 141px 141px 141px 141px;
          border-radius: 141px 141px 141px 141px;
}
.activity-tab-container .activity-content {
  height: 351px;
  display: none;
}
.activity-tab-container .activity-content.active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.activity-tab-container .activity-content .activity-content-left {
  -webkit-border-radius: 28px;
          border-radius: 28px;
}
.activity-tab-container .activity-content .activity-content-right {
  -webkit-border-radius: 28px;
          border-radius: 28px;
}
.activity-tab-container .activity-content .activity-content-left {
  z-index: 1;
  width: 624px;
  -webkit-animation: bounceInLeft 1s ease-out !important;
     -moz-animation: bounceInLeft 1s ease-out !important;
          animation: bounceInLeft 1s ease-out !important;
}
.activity-tab-container .activity-content .activity-content-left .activity-content-swiper {
  -webkit-border-radius: 28px;
          border-radius: 28px;
  width: 100%;
  height: 100%;
}
.activity-tab-container .activity-content .activity-content-left .activity-content-swiper .activity-content-swiper-slide {
  width: 100%;
}
.activity-tab-container .activity-content .activity-content-left .activity-content-swiper .activity-content-swiper-slide .activity-content-swiper-item {
  width: 100%;
}
.activity-tab-container .activity-content .activity-content-left .activity-content-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 16px;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.activity-tab-container .activity-content .activity-content-left .activity-content-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 32px;
  height: 8px;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.2;
  margin: 0 4px;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}
.activity-tab-container .activity-content .activity-content-left .activity-content-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #F5333F;
  opacity: 1;
}
.activity-tab-container .activity-content .activity-content-right {
  z-index: 2;
  margin-left: -48px;
  width: 624px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.85)), color-stop(15%, #FFFFFF), to(#FFFFFF));
  background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0.85) 0%, #FFFFFF 15%, #FFFFFF 100%);
  background: -moz-linear-gradient(right, rgba(255, 255, 255, 0.85) 0%, #FFFFFF 15%, #FFFFFF 100%);
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.85) 0%, #FFFFFF 15%, #FFFFFF 100%);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(252, 222, 212, 0.42);
          box-shadow: 0px 8px 24px 0px rgba(252, 222, 212, 0.42);
  border: 1px solid #fff;
  padding: 0 48px;
  -webkit-animation: bounceInRight 1s ease-out !important;
     -moz-animation: bounceInRight 1s ease-out !important;
          animation: bounceInRight 1s ease-out !important;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.activity-tab-container .activity-content .activity-content-right .activity-content-title {
  font-weight: 500;
  font-size: 24px;
  color: #1A1A1A;
  line-height: 36px;
  text-align: left;
}
.activity-tab-container .activity-content .activity-content-right .activity-content-desc {
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  color: #4D4D4D;
  line-height: 24px;
  text-align: left;
}
