.more-course-page {
  width: 100% !important;
}
.more-course-page .main-container-width {
  width: 1200px;
  margin: 0 auto;
}
.more-course-page .course-container {
  padding-top: 80px;
  height: 877px;
  background: url(https://img.qlchat.com/qlLive/activity/image/3YR46EZI-NQTO-6YH4-1709191019468-9O8HHNKFRS11.png) center / 100% 100% no-repeat;
}
.more-course-page .column-title {
  text-align: center;
  padding-top: 80px;
}
.more-course-page .column-title .column-title-img {
  width: auto;
  height: 46px;
}
.more-course-page .column-title .column-title-desc {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 24px;
}
.more-course-page .column-title img {
  width: auto;
  height: 104px;
}
