.homework-data-wrap {
  margin: 0px;
  padding-bottom: 48px;
}
.homework-data-wrap.first {
  margin-top: 24px;
}
.homework-data-wrap .inner-wrap {
  padding: 24px;
  overflow: hidden;
  background: #F7F7F7;
  -webkit-border-radius: 8px;
          border-radius: 8px;
}
.homework-data-wrap .inner-wrap .question-wrap {
  max-height: 129px;
  overflow: hidden;
  position: relative;
  line-height: 25px;
  font-size: 14px;
  color: #666;
}
.homework-data-wrap .inner-wrap .question-wrap.height-auto {
  max-height: none;
}
.homework-data-wrap .inner-wrap .question-wrap .title {
  padding: 0 8px;
  height: 24px;
  background: #FFFFFF;
  -webkit-border-radius: 4px 4px 14px 4px;
          border-radius: 4px 4px 14px 4px;
  border: 1px solid #BBBBBB;
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -moz-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  display: inline-block;
  margin-right: 16px;
  font-weight: bold;
}
.homework-data-wrap .inner-wrap .question-wrap .title > img {
  width: 7px;
  margin: 0 2px;
  display: block;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap {
  margin-left: -16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  position: relative;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box {
  width: -moz-calc(100% / 3 - 16px);
  width: calc(100% / 3 - 16px);
  height: 0;
  padding-top: -moz-calc(100% / 3 - 16px);
  padding-top: calc(100% / 3 - 16px);
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 16px;
          border-radius: 16px;
  margin-left: 16px;
  margin-top: 16px;
  background-color: #000;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box .img-contain {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  cursor: pointer;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box .video-play-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  top: 16px;
  right: 16px;
  z-index: 1;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding .transcoding-icon {
  width: 72px;
  height: 72px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding .transcoding-icon img {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate360 1s infinite linear;
     -moz-animation: rotate360 1s infinite linear;
          animation: rotate360 1s infinite linear;
}
.homework-data-wrap .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding .transcoding-tips {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
.homework-data-wrap .inner-wrap .question-wrap .control-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #333;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 25px;
}
.homework-data-wrap .inner-wrap .question-wrap .control-btn .point {
  display: block;
  width: 80px;
  text-align: right;
  background: -webkit-gradient(linear, right top, left top, from(#f7f7f7), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(right, #f7f7f7, rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(right, #f7f7f7, rgba(255, 255, 255, 0));
  background: linear-gradient(to left, #f7f7f7, rgba(255, 255, 255, 0));
  font-size: 30px;
  height: 24px;
}
.homework-data-wrap .inner-wrap .question-wrap .control-btn .text-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #f7f7f7;
  padding: 0 8px;
}
.homework-data-wrap .inner-wrap .question-wrap .control-btn .text-wrap .iconfont {
  font-size: 14px;
  margin-left: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.homework-data-wrap .answer-wrap {
  margin-top: 24px;
}
.homework-data-wrap .answer-wrap .title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 33px;
}
.homework-data-wrap .answer-wrap .title::before {
  content: '';
  display: block;
  width: 6px;
  height: 20px;
  background: #F5333F;
  -webkit-border-radius: 0px 4px 4px 0px;
          border-radius: 0px 4px 4px 0px;
  margin-right: 16px;
}
.homework-data-wrap .answer-wrap .content {
  font-size: 16px;
  line-height: 28px;
  margin-top: 12px;
}
.homework-data-wrap .answer-wrap .img-wrap {
  margin-left: -16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box {
  width: -moz-calc(100% / 3 - 16px);
  width: calc(100% / 3 - 16px);
  height: 0;
  padding-top: -moz-calc(100% / 3 - 16px);
  padding-top: calc(100% / 3 - 16px);
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 16px;
          border-radius: 16px;
  margin-left: 16px;
  margin-top: 16px;
  background-color: #000;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box .img-contain {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box .video-play-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  top: 16px;
  right: 16px;
  z-index: 1;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box .video-transcoding {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box .video-transcoding .transcoding-icon {
  width: 72px;
  height: 72px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box .video-transcoding .transcoding-icon img {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate360 1s infinite linear;
     -moz-animation: rotate360 1s infinite linear;
          animation: rotate360 1s infinite linear;
}
.homework-data-wrap .answer-wrap .img-wrap .img-box .video-transcoding .transcoding-tips {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
