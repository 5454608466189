.teacher-fine-evaluation-mobile {
  position: relative;
  padding-top: 0.226667rem;
  margin-bottom: 0.426667rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable {
  position: absolute;
  height: 0.426667rem;
  top: 0;
  left: 0.32rem;
  background: -webkit-linear-gradient(225deg, #FF4B4B 0%, #FF8282 100%);
  background: -moz-linear-gradient(225deg, #FF4B4B 0%, #FF8282 100%);
  background: linear-gradient(225deg, #FF4B4B 0%, #FF8282 100%);
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.053333rem 0.106667rem 0.053333rem 0.053333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .teacher-img {
  width: 0.373333rem;
  height: 0.373333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .teacher-name {
  font-size: 0.266667rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.32rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .line {
  height: 0.426667rem;
  background: rgba(255, 255, 255, 0.4);
  margin: 0 0.106667rem;
}

[data-dpr="1"] .teacher-fine-evaluation-mobile .fine-evaluation-lable .line {
  width: 0.5px;
}

[data-dpr="2"] .teacher-fine-evaluation-mobile .fine-evaluation-lable .line {
  width: 1px;
}

[data-dpr="3"] .teacher-fine-evaluation-mobile .fine-evaluation-lable .line {
  width: 1.5px;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .star-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .star-box .star {
  width: 0.266667rem;
  height: 0.266667rem;
  margin-right: 0.026667rem;
  background-image: url(https://img.qlchat.com/qlLive/activity/image/VTJLFY13-XG9U-A6EV-1645774266005-NFCJN1HA1H4D.png?x-oss-process=image/format,webp);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .star-box .star:last-child {
  margin-right: 0;
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .star-box .star.half {
  background-image: url(https://img.qlchat.com/qlLive/activity/image/9G3QVRC9-PA49-YMIH-1645774268086-UBXTB3TW6BR9.png?x-oss-process=image/format,webp);
}

.teacher-fine-evaluation-mobile .fine-evaluation-lable .star-box .star.empty {
  background-image: url(https://img.qlchat.com/qlLive/activity/image/J3F3M2DJ-GWQP-EG2K-1645774269694-VALWKTY1WB93.png?x-oss-process=image/format,webp);
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain {
  background: #FFF2F2;
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
  padding: 0.306667rem 0.32rem 0.213333rem;
  font-size: 0.346667rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.64rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .detaile {
  white-space: pre-line;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .audio-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 0.106667rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .audio-box.detail {
  margin-bottom: -0.213333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .audio-box .detail {
  margin-right: 0.32rem;
  margin-bottom: 0.213333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .media-tips {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.213333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .media-tips .tips-icon {
  -webkit-border-radius: 0.253333rem;
  border-radius: 0.253333rem;
  border: 1px solid rgba(245, 51, 63, 0.3);
  font-size: 0.266667rem;
  font-weight: 400;
  color: #f5333f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.213333rem;
  line-height: normal;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.026667rem 0.213333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-contain .media-tips .tips-icon i {
  font-size: 0.32rem;
  margin-right: 0.053333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.106667rem;
  margin-top: 0.213333rem;
  margin-bottom: 0.106667rem;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  overflow: hidden;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box {
  width: 100%;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background-color: #000;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box .teacher-comment-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box .video-play-icon {
  position: absolute;
  width: 0.426667rem;
  height: 0.426667rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 0.213333rem;
  right: 0.213333rem;
  z-index: 1;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box .video-transcoding {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box .video-transcoding .transcoding-icon {
  width: 0.96rem;
  height: 0.96rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box .video-transcoding .transcoding-icon img {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate360 1s infinite linear;
  -moz-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
}

.teacher-fine-evaluation-mobile .fine-evaluation-imgs .img-box .video-transcoding .transcoding-tips {
  margin-top: 0.213333rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.44rem;
}