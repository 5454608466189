.category-module-mobile {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.category-module-mobile .fadeInUp {
  -webkit-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
  -moz-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
  animation: fadeInUp 0.3s ease-in-out 0s 1 both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.426667rem, 0);
    -moz-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

.category-module-mobile .main-container .main-container-bg {
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.category-module-mobile .main-container .main-container-bg.first-screen {
  padding: 2.346667rem 0.426667rem 1.173333rem;
}

.category-module-mobile .main-container .main-container-bg.second-screen {
  padding: 1.173333rem 0 0;
}

.category-module-mobile .main-container .main-container-bg.third-screen {
  padding: 1.173333rem 0;
}

.category-module-mobile .main-container .main-container-bg.fourth-screen {
  padding: 1.026667rem 0.426667rem 0.853333rem;
}

.category-module-mobile .main-container .category-module-mobile-title {
  margin-bottom: 0.426667rem;
  text-align: center;
}

.category-module-mobile .main-container .category-module-mobile-title img {
  height: 0.733333rem;
}

.category-module-mobile .main-container .category-module-mobile-desc {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #4D4D4D;
  line-height: 0.586667rem;
  text-align: left;
  margin-bottom: 0.426667rem;
  line-break: auto;
}

.category-module-mobile .main-container .category-module-mobile-desc.second-desc {
  padding: 0 0.426667rem;
}

.category-module-mobile .main-container .category-module-mobile-banner {
  width: 100%;
  -webkit-border-radius: 0.106667rem;
  border-radius: 0.106667rem;
}

.category-module-mobile .category-module-img-text-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.category-module-mobile .category-module-img-text-list::-webkit-scrollbar {
  display: none;
}

.category-module-mobile .category-module-img-text-list .img-text-item {
  width: 5.52rem;
  background: #F8F8FB;
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  overflow: hidden;
  margin-right: 0.426667rem;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

.category-module-mobile .category-module-img-text-list .img-text-item:last-child {
  margin-right: 0;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-img {
  width: 100%;
  height: 7.36rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-theme {
  width: 5.52rem;
  height: 1.28rem;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(216, 216, 216, 0)), to(rgba(0, 0, 0, 0.4)));
  background: -webkit-linear-gradient(top, rgba(216, 216, 216, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -moz-linear-gradient(top, rgba(216, 216, 216, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(180deg, rgba(216, 216, 216, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  font-weight: 400;
  font-size: 0.4rem;
  color: #FFFFFF;
  line-height: 0.64rem;
  text-align: left;
  position: absolute;
  left: 0;
  top: 6.08rem;
  padding-top: 0.426667rem;
  padding-left: 0.32rem;
  padding-right: 0.32rem;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-theme p {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content {
  padding: 0.213333rem 0.32rem 0.32rem 0.32rem;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .title {
  height: 1.28rem;
  font-weight: 500;
  font-size: 0.4rem;
  color: #1A1A1A;
  line-height: 0.64rem;
  text-align: left;
  position: relative;
  text-indent: 0.506667rem;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .title::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0.106667rem;
  width: 0.386667rem;
  height: 0.426667rem;
  background: url(https://img.qlchat.com/qlLive/activity/image/D292XMF7-QDFX-SIP1-1709287461253-LC9NXKTIBYHR.png) center no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.213333rem;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .user-info-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .user-info-left .avatar {
  border: 0.013333rem solid #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 0.106667rem;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .user-info-left .avatar img {
  width: 0.533333rem;
  height: 0.533333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .user-info-left span {
  width: 2.133333rem;
  font-weight: 400;
  font-size: 0.32rem;
  line-height: 0.533333rem;
  color: #4D4D4D;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .like-num {
  height: 0.533333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .like-num i {
  font-size: 0.426667rem;
  margin-right: 0.106667rem;
  line-height: 0.426667rem;
}

.category-module-mobile .category-module-img-text-list .img-text-item .img-text-content .user-info .like-num span {
  font-size: 0.32rem;
  line-height: 0.32rem;
}

.category-module-mobile .more-story {
  width: 3.52rem;
  height: 0.853333rem;
  padding-left: 0.426667rem;
  -webkit-border-radius: 1.2rem;
  border-radius: 1.2rem;
  border: 0.013333rem solid #F5333F;
  font-weight: 400;
  font-size: 0.4rem;
  color: #F5333F;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.533333rem auto 0;
}

.category-module-mobile .more-story i {
  font-size: 0.266667rem;
  margin-left: 0.133333rem;
}

.span-html p {
  display: inline;
}