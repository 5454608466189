.teacher-fine-evaluation {
  position: relative;
  padding-top: 17px;
  margin-bottom: 32px;
}
.teacher-fine-evaluation .fine-evaluation-lable {
  position: absolute;
  height: 24px;
  top: 0;
  left: 24px;
  background: -webkit-gradient(linear, right top, left top, from(#FF4B4B), to(#FF6B6B));
  background: -webkit-linear-gradient(right, #FF4B4B 0%, #FF6B6B 100%);
  background: -moz-linear-gradient(right, #FF4B4B 0%, #FF6B6B 100%);
  background: linear-gradient(270deg, #FF4B4B 0%, #FF6B6B 100%);
  -webkit-border-radius: 16px;
          border-radius: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px 16px 4px 4px;
}
.teacher-fine-evaluation .fine-evaluation-lable.clock-in {
  padding: 4px 8px 4px 4px;
}
.teacher-fine-evaluation .fine-evaluation-lable .teacher-img {
  width: 16px;
}
.teacher-fine-evaluation .fine-evaluation-lable .teacher-name {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 14px;
}
.teacher-fine-evaluation .fine-evaluation-lable .line {
  width: 1px;
  /*px*/
  height: 16px;
  background: rgba(255, 255, 255, 0.4);
  margin: 0 4px;
}
.teacher-fine-evaluation .fine-evaluation-lable .star-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teacher-fine-evaluation .fine-evaluation-lable .star-box .star {
  width: 11px;
  height: 11px;
  margin-right: 2px;
  background-image: url(https://img.qlchat.com/qlLive/activity/image/VTJLFY13-XG9U-A6EV-1645774266005-NFCJN1HA1H4D.png?x-oss-process=image/format,webp);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}
.teacher-fine-evaluation .fine-evaluation-lable .star-box .star:last-child {
  margin-right: 0;
}
.teacher-fine-evaluation .fine-evaluation-lable .star-box .star.half {
  background-image: url(https://img.qlchat.com/qlLive/activity/image/9G3QVRC9-PA49-YMIH-1645774268086-UBXTB3TW6BR9.png?x-oss-process=image/format,webp);
}
.teacher-fine-evaluation .fine-evaluation-lable .star-box .star.empty {
  background-image: url(https://img.qlchat.com/qlLive/activity/image/J3F3M2DJ-GWQP-EG2K-1645774269694-VALWKTY1WB93.png?x-oss-process=image/format,webp);
}
.teacher-fine-evaluation .fine-evaluation-contain {
  background: #FFF2F2;
  -webkit-border-radius: 24px;
          border-radius: 24px;
  padding: 23px 24px 16px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.teacher-fine-evaluation .fine-evaluation-contain .detaile {
  white-space: pre-line;
}
.teacher-fine-evaluation .fine-evaluation-contain .audio-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 8px;
}
.teacher-fine-evaluation .fine-evaluation-contain .audio-box.detail {
  margin-bottom: -16px;
}
.teacher-fine-evaluation .fine-evaluation-contain .audio-box .detail {
  margin-right: 24px;
  margin-bottom: 16px;
}
.teacher-fine-evaluation .fine-evaluation-contain .media-tips {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 16px;
}
.teacher-fine-evaluation .fine-evaluation-contain .media-tips .tips-icon {
  -webkit-border-radius: 19px;
          border-radius: 19px;
  border: 1px solid rgba(245, 51, 63, 0.3);
  /*no*/
  font-size: 20px;
  font-weight: 400;
  color: #f5333f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 16px;
  line-height: normal;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 2px 16px;
}
.teacher-fine-evaluation .fine-evaluation-contain .media-tips .tips-icon i {
  font-size: 24px;
  margin-right: 4px;
}
.teacher-fine-evaluation .fine-evaluation-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  -webkit-border-radius: 16px;
          border-radius: 16px;
  overflow: hidden;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box {
  width: 100%;
  -webkit-border-radius: 16px;
          border-radius: 16px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background-color: #000;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box .teacher-comment-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 16px;
          border-radius: 16px;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box .video-play-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  top: 16px;
  right: 16px;
  z-index: 1;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box .video-transcoding {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box .video-transcoding .transcoding-icon {
  width: 72px;
  height: 72px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box .video-transcoding .transcoding-icon img {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate360 1s infinite linear;
     -moz-animation: rotate360 1s infinite linear;
          animation: rotate360 1s infinite linear;
}
.teacher-fine-evaluation .fine-evaluation-imgs .img-box .video-transcoding .transcoding-tips {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
