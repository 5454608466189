.home-training-teacher {
  margin-top: 36px !important;
  padding-bottom: 64px;
}
.home-training-teacher .home-training-teacher-item {
  height: 446px;
  background: #fff url('https://img.qlchat.com/qlLive/activity/image/VOGIUJBL-ENHK-2UYW-1629859825282-5XH85LYDY8VN.png?x-oss-process=image/format,webp') no-repeat 0 0;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  position: relative;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: none;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content.active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-left {
  padding: 38px 64px;
  visibility: visible !important;
  -webkit-animation-name: bounceInLeft !important;
     -moz-animation-name: bounceInLeft !important;
          animation-name: bounceInLeft !important;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-left .home-training-teacher-item-name {
  font-size: 40px;
  font-weight: 600;
  color: #333333;
  line-height: 56px;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-left .home-training-teacher-item-intro {
  margin-top: 10px;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  padding-left: 19px;
  position: relative;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-left .home-training-teacher-item-intro::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 20px;
  background: #f4313f;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%);
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-left .home-training-teacher-item-detail {
  margin-top: 68px;
  font-size: 16px;
  color: #999999;
  line-height: 24px;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-right {
  width: 500px;
  height: 410px;
  visibility: visible !important;
  -webkit-animation-name: bounceInRight !important;
     -moz-animation-name: bounceInRight !important;
          animation-name: bounceInRight !important;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-content .home-training-teacher-item-right img {
  width: 100%;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-arrow {
  position: absolute;
  left: 60px;
  bottom: 51px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-arrow .item-arrow-icon {
  width: 32px;
  height: 32px;
  background: url('https://img.qlchat.com/qlLive/activity/image/AHDV9ZLX-DFZW-LWLP-1629879701091-Z6X8JPU8O4PP.png?x-oss-process=image/format,webp') no-repeat 0 0;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  cursor: pointer;
}
.home-training-teacher .home-training-teacher-item .home-training-teacher-item-arrow .item-arrow-right {
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 46px;
}
