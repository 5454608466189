.home-page-wrap {
  color: red;
  font-size: 40px;
  background: #fff;
  color: #333;
  font-family: AlibabaPuHuiTiR;
}
.home-page-wrap .main-ctn-wrap2 {
  width: 1210px;
  margin: 0 auto;
  background: #fff;
}
.home-page-wrap .main-ctn-wrap2 span {
  font-family: AlibabaPuHuiTiR !important;
  font-size: 20px !important;
}
.home-page-wrap .main-ctn-wrap2 > table {
  width: 1210px !important;
}
.home-page-wrap .main-ctn-wrap2 > table td {
  border: 1px solid #000 !important;
}
.home-page-wrap .hide {
  display: none;
}
.home-page-wrap img {
  display: block;
}
.home-page-wrap .white-bg {
  width: 100%;
  height: 80px;
  background: #fff;
}
.home-page-wrap .top-outer-wrap {
  position: fixed;
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #fff;
}
.home-page-wrap .top-tab-wrap {
  width: 1210px;
  height: 80px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
}
.home-page-wrap .top-tab-wrap .logo-img {
  width: 146px;
  margin-right: 84px;
}
.home-page-wrap .top-tab-wrap .tab-wrap {
  height: 80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.home-page-wrap .top-tab-wrap .tab-wrap > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  position: relative;
  font-weight: bold;
  margin-right: 51px;
  cursor: pointer;
}
.home-page-wrap .top-tab-wrap .tab-wrap > div.active {
  color: #F5333F;
}
.home-page-wrap .top-tab-wrap .tab-wrap > div.active::after {
  content: '';
  display: block;
  width: 32px;
  height: 3px;
  background: #F5333F;
  -webkit-border-radius: 2px;
          border-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
}
.home-page-wrap .banner-img-wrap {
  width: 100%;
  min-width: 1210px;
  height: 548px;
  position: relative;
}
.home-page-wrap .banner-img-wrap > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-page-wrap .banner-img-wrap #id2 {
  position: absolute;
  left: 0;
  bottom: 80px;
}
.home-page-wrap .course-wrap {
  width: 100%;
  min-width: 1210px;
  background: -webkit-gradient(linear, left top, left bottom, from(#F9F9FB), to(#FFFFFF));
  background: -webkit-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, #F9F9FB 0%, #FFFFFF 100%);
  padding: 72px 0;
  position: relative;
}
.home-page-wrap .course-wrap #id3 {
  position: absolute;
  left: 0;
  bottom: 80px;
}
.home-page-wrap .course-wrap .title-img {
  width: 1072px;
  margin: 0 auto;
  display: block;
}
.home-page-wrap .course-wrap .course-box-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: 1210px;
  margin-top: 38px;
}
.home-page-wrap .course-wrap .course-box-wrap > div {
  width: 384px;
  height: 315px;
  background: #FFFFFF;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  border: 1px solid #EEEEEE;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 29px;
  cursor: pointer;
}
.home-page-wrap .course-wrap .course-box-wrap > div:last-child {
  margin-right: 0;
}
.home-page-wrap .course-wrap .course-box-wrap > div .course-img {
  width: 100%;
  height: 239px;
}
.home-page-wrap .course-wrap .course-box-wrap > div .text {
  width: 100%;
  height: 76px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.home-page-wrap .company-intro-wrap {
  width: 100%;
  min-width: 1210px;
  background: #F9F9FB;
  padding: 128px 0;
}
.home-page-wrap .company-intro-wrap .title-img {
  width: 548px;
  margin: 0 auto;
  display: block;
}
.home-page-wrap .company-intro-wrap .detail {
  width: 1196px;
  margin: 0 auto;
  margin-top: 26px;
  font-size: 24px;
  color: #666;
  line-height: 33px;
}
.home-page-wrap .company-intro-wrap .detail > div {
  width: 100%;
  margin-bottom: 50px;
}
.home-page-wrap .history-wrap {
  width: 100%;
  min-width: 1210px;
  background: #F9F9FB;
  padding: 128px 0 156px;
}
.home-page-wrap .history-wrap .title-img {
  width: 526px;
  display: block;
  margin: 0 auto;
}
.home-page-wrap .history-wrap .detail-wrap {
  margin-top: 33px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap {
  text-align: right;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap {
  text-align: left;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .title,
.home-page-wrap .history-wrap .detail-wrap .right-wrap .title {
  font-size: 24px;
  color: #F5333F;
  font-weight: bold;
  line-height: 33px;
  white-space: nowrap;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .subtitle,
.home-page-wrap .history-wrap .detail-wrap .right-wrap .subtitle {
  font-size: 28px;
  line-height: 38px;
  font-weight: bold;
  margin-top: 12px;
  white-space: nowrap;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .text,
.home-page-wrap .history-wrap .detail-wrap .right-wrap .text {
  font-size: 20px;
  color: #666;
  line-height: 27px;
  margin-top: 12px;
  white-space: nowrap;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line {
  margin: 0 39px;
  height: 2851px;
  width: 2px;
  background: #E8E8E9;
  -webkit-border-radius: 1px;
          border-radius: 1px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line > div {
  width: 12px;
  height: 12px;
  background: #F5333F;
  -webkit-border-radius: 50%;
          border-radius: 50%;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point1 {
  margin-top: 50px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point2 {
  margin-top: 270px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point3 {
  margin-top: 215px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point4 {
  margin-top: 225px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point5 {
  margin-top: 215px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point6 {
  margin-top: 215px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point7 {
  margin-top: 280px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point8 {
  margin-top: 242px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point9 {
  margin-top: 225px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point10 {
  margin-top: 280px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point11 {
  margin-top: 188px;
}
.home-page-wrap .history-wrap .detail-wrap .middle-line .point12 {
  margin-top: 253px;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .data1 {
  margin-top: 40px;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .data2 {
  margin-top: 267px;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .data3 {
  margin-top: 267px;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .data4 {
  margin-top: 332px;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .data5 {
  margin-top: 277px;
}
.home-page-wrap .history-wrap .detail-wrap .left-wrap .data6 {
  margin-top: 240px;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap .data1 {
  margin-top: 322px;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap .data2 {
  margin-top: 277px;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap .data3 {
  margin-top: 267px;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap .data4 {
  margin-top: 294px;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap .data5 {
  margin-top: 332px;
}
.home-page-wrap .history-wrap .detail-wrap .right-wrap .data6 {
  margin-top: 305px;
}
.home-page-wrap .bottom-wrap {
  width: 1210px;
  margin: 0 auto;
  background: #fff;
  padding: 62px 60px 82px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.home-page-wrap .bottom-wrap .title {
  font-size: 18px;
  line-height: 25px;
}
.home-page-wrap .bottom-wrap .text {
  font-size: 14px;
  line-height: 20px;
  color: #666;
  margin-top: 18px;
}
.home-page-wrap .bottom-wrap .text.text1 {
  margin-top: 32px;
}
