.home-training-top-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #F9F9FB;
  position: relative;
}
.home-training-top-banner .brand-video-container {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1200px;
  height: 600px;
  z-index: 2;
  pointer-events: none;
}
.home-training-top-banner .brand-video {
  pointer-events: all;
  position: absolute;
  top: 105px;
  left: 0;
  width: 320px;
  height: 456px;
  background: -webkit-linear-gradient(280deg, rgba(245, 243, 243, 0.6) 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(280deg, rgba(245, 243, 243, 0.6) 0%, #FFFFFF 100%);
  background: linear-gradient(170deg, rgba(245, 243, 243, 0.6) 0%, #FFFFFF 100%);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(240, 211, 201, 0.62);
          box-shadow: 0px 8px 24px 0px rgba(240, 211, 201, 0.62);
  -webkit-border-radius: 24px 24px 24px 24px;
          border-radius: 24px 24px 24px 24px;
  border: 2px solid #FFFFFF;
  -webkit-border-radius: 24px;
          border-radius: 24px;
  z-index: 2;
  text-align: center;
}
.home-training-top-banner .brand-video .brand-name {
  font-size: 24px;
  font-weight: 500;
  color: #1A1A1A;
  line-height: 36px;
  padding-top: 40px;
}
.home-training-top-banner .brand-video .brand-desc {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 22px;
}
.home-training-top-banner .brand-video .brand-video-box {
  margin: 48px auto 0;
  width: 304px;
  height: 294px;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFC8C2), color-stop(99%, rgba(255, 214, 217, 0.1)));
  background: -webkit-linear-gradient(top, #FFC8C2 0%, rgba(255, 214, 217, 0.1) 99%);
  background: -moz-linear-gradient(top, #FFC8C2 0%, rgba(255, 214, 217, 0.1) 99%);
  background: linear-gradient(180deg, #FFC8C2 0%, rgba(255, 214, 217, 0.1) 99%);
  -webkit-border-radius: 16px 16px 16px 16px;
          border-radius: 16px 16px 16px 16px;
}
.home-training-top-banner .brand-video .brand-video-box .course-img {
  margin-top: 8px;
  width: 288px;
  height: 146px;
  -webkit-border-radius: 12px;
          border-radius: 12px;
}
.home-training-top-banner .brand-video .brand-video-box .brand-video-btn {
  height: 88px;
  width: 320px;
  margin-top: 36px;
  background: url(https://img.qlchat.com/qlLive/activity/image/OCMFDUVX-FCPY-RFSD-1710139341495-3AC4N7PWGDHA.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.home-training-top-banner .brand-video .brand-video-box .brand-video-btn span {
  margin-top: -10px;
}
.home-training-top-banner .brand-video .brand-video-box .brand-video-btn .brand-video-btn-icon {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: -8px;
}
.home-training-top-banner .home-training-top-banne-swiper {
  width: 100%;
  height: 600px;
  cursor: pointer;
  position: relative;
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-button-prev,
.home-training-top-banner .home-training-top-banne-swiper .swiper-button-next {
  width: 64px;
  height: 64px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
  opacity: 1 !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-button-prev::after,
.home-training-top-banner .home-training-top-banne-swiper .swiper-button-next::after {
  font-size: 14px;
  font-weight: bold;
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-button-prev {
  -webkit-transform: translate(-100%, -50%);
     -moz-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  left: 0;
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-button-next {
  -webkit-transform: translate(100%, -50%);
     -moz-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  right: 0;
}
.home-training-top-banner .home-training-top-banne-swiper .home-training-top-banne-swiper-img {
  width: 100%;
  height: 100%;
  -webkit-background-size: auto 100%;
          background-size: auto 100%;
}
.home-training-top-banner .home-training-top-banne-swiper:hover .swiper-button-prev {
  -webkit-transform: translate(50%, -50%);
     -moz-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.home-training-top-banner .home-training-top-banne-swiper:hover .swiper-button-next {
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 40px;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 32px;
  height: 8px;
  background: rgba(0, 0, 0, 0.08);
  opacity: 1;
  margin: 0 4px;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}
.home-training-top-banner .home-training-top-banne-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 32px;
  height: 8px;
  background: #F5333F;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  opacity: 1;
}
