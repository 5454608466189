.page-footer-mobile {
  background: #fff;
  position: relative;
  overflow: hidden;
  border-top: 0.04rem solid #f5333f;
}

.page-footer-mobile .pdrl32 {
  padding: 0 0.426667rem;
}

.page-footer-mobile .main-title {
  font-size: 0.426667rem !important;
  font-weight: 700 !important;
  color: #1a1a1a !important;
  line-height: 0.493333rem !important;
}

.page-footer-mobile .prds {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 0.853333rem;
}

.page-footer-mobile .prds .prds-list {
  padding-left: 0.426667rem;
}

.page-footer-mobile .prds .prds-list .prds-list-item-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.32rem 0 0.64rem;
}

.page-footer-mobile .prds .prds-list .prds-list-item {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.586667rem;
  margin-right: 0.64rem;
}

.page-footer-mobile .contact-box {
  padding-bottom: 0.64rem;
  border-bottom: 0.026667rem solid #EEEEEE;
}

.page-footer-mobile .contact-box .phone {
  margin-top: 0.426667rem;
}

.page-footer-mobile .contact-box .text-font {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 0.493333rem;
  margin-bottom: 0.64rem;
}

.page-footer-mobile .contact-box .code-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-footer-mobile .contact-box .code-list .code-list-item:first-child {
  margin-right: 0.853333rem;
}

.page-footer-mobile .contact-box .code-list .code-list-item img {
  width: 2.56rem;
  height: 2.56rem;
}

.page-footer-mobile .contact-box .code-list .code-list-item .name {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 0.44rem;
  text-align: center;
  margin-top: 0.213333rem;
}

.page-footer-mobile .other-ms {
  margin-top: 0.426667rem;
  margin-bottom: 2.56rem;
  text-align: center;
  padding: 0 0.426667rem;
}

.page-footer-mobile .other-ms .ms-details {
  font-size: 0.346667rem;
  font-weight: 400;
  color: #808080;
  line-height: 0.44rem;
}

.page-footer-mobile .other-ms .ms-details:not(:last-child) {
  margin-bottom: 0.213333rem;
}