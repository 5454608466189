.home-about-mobile {
  margin-top: 0.64rem;
}

.home-about-mobile .about-title {
  text-align: center;
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
}

.home-about-mobile .intro {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.666667rem;
  margin-top: 0.426667rem;
  padding: 0 0.426667rem;
  text-align: center;
}

.home-about-mobile .more-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 0.426667rem;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #F5333F;
  line-height: 0.533333rem;
}

.home-about-mobile .more-btn i {
  margin-left: 0.106667rem;
  font-size: 0.266667rem;
}

.home-about-mobile .video-box {
  margin-top: 0.746667rem;
  margin: 0.746667rem auto 0;
  width: 8.72rem;
  -webkit-border-radius: 0.08rem;
  border-radius: 0.08rem;
  position: relative;
  overflow: hidden;
}

.home-about-mobile .video-box .video-bg {
  width: 100%;
}

.home-about-mobile .video-box .play-btn {
  width: 1.493333rem;
  height: 1.493333rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}