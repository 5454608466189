@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-moz-keyframes rotate360 {
  0% {
    -moz-transform: rotate(0);
         transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
         transform: rotate(360deg);
  }
}
@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.audio-item-box {
  width: 246px;
  height: 48px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.audio-item-box .contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  -webkit-border-radius: 40px;
          border-radius: 40px;
  border: 2px solid #FCC1C5;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8px;
}
.audio-item-box .contain .box-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.audio-item-box .contain .box-left .playIcon {
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF4B4B), to(#FF8282));
  background: -webkit-linear-gradient(top, #FF4B4B 0%, #FF8282 100%);
  background: -moz-linear-gradient(top, #FF4B4B 0%, #FF8282 100%);
  background: linear-gradient(180deg, #FF4B4B 0%, #FF8282 100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.audio-item-box .contain .box-left .playIcon i {
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.audio-item-box .contain .box-left .playIcon .play {
  width: 100%;
  height: 100%;
}
.audio-item-box .contain .box-left .playIcon .pause {
  width: 100%;
  height: 100%;
}
.audio-item-box .contain .box-left .playIcon .loading {
  width: 20px;
  height: 20px;
  -webkit-animation: rotate360 1s infinite linear;
     -moz-animation: rotate360 1s infinite linear;
          animation: rotate360 1s infinite linear;
}
.audio-item-box .contain .box-center {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin: 0 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.audio-item-box .contain .box-center .stop {
  width: 100%;
}
.audio-item-box .contain .box-center .audio-progress-contain {
  position: relative;
  width: 100%;
  height: 6px;
  background: rgba(245, 51, 63, 0.3);
  -webkit-border-radius: 3px;
          border-radius: 3px;
}
.audio-item-box .contain .box-center .audio-progress-contain .audio-progress-touch {
  position: absolute;
  width: 100%;
  left: 0;
  height: 34px;
  top: -15px;
  z-index: 1;
}
.audio-item-box .contain .box-center .audio-progress-contain .audio-progress {
  -webkit-border-radius: 3px;
          border-radius: 3px;
  height: 5px;
  background: #f5333f;
  position: absolute;
  left: 0;
  top: 0;
}
.audio-item-box .contain .box-center .audio-progress-contain .audio-progress.not-progress {
  -webkit-transition: width 1s linear;
  -moz-transition: width 1s linear;
  transition: width 1s linear;
}
.audio-item-box .contain .box-center .audio-progress-contain .audio-progress .circle {
  width: 20px;
  height: 20px;
  background: #ffffff;
  position: absolute;
  right: -11px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  top: -8px;
  border: 2px solid #f5333f;
}
.audio-item-box .contain .box-right {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: bold;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 8px 0 0;
}
