.home-training-step {
  margin-top: 36px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 500px;
  padding-bottom: 64px;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}
.home-training-step .step-item-img {
  width: 847px;
  height: 100%;
}
.home-training-step .step-item-img img {
  height: 100%;
  width: 100%;
  vertical-align: middle;
  display: none;
  -webkit-border-radius: 20px;
          border-radius: 20px;
}
.home-training-step .step-item-img img.active {
  display: block;
}
.home-training-step .step-item-tab {
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin-left: 32px;
  padding-top: 43px;
  background: #fff;
  -webkit-border-radius: 20px;
          border-radius: 20px;
}
.home-training-step .step-item-tab .step-item-tab-logo {
  width: 137px;
  padding-left: 26px;
}
.home-training-step .step-item-tab .step-item-tab-logo img {
  width: 100%;
}
.home-training-step .step-item-tab .step-item-tab-num {
  margin-top: 48px;
  position: relative;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text {
  position: relative;
  z-index: 1;
  padding: 24px 24px 24px 26px;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text .title {
  font-size: 20px;
  line-height: 28px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text .title span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  background: #cccccc;
  font-size: 14px;
  font-weight: 600;
  color: #fdfdfd;
  vertical-align: middle;
  margin-right: 10px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  line-height: normal;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text .details {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #F5333F;
  line-height: 21px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  height: 63px;
  overflow: hidden;
  opacity: 1;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text .details.hidden-details {
  height: 0;
  margin-top: 0px;
  overflow: hidden;
  opacity: 0;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text.active {
  background-color: #FFEFF0;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/TZM3H48P-36WP-1HRZ-1653383419522-AUZVXBX35B1X.png?x-oss-process=image/format,webp");
  background-repeat: no-repeat;
  position: relative;
  padding: 12px 24px 12px 26px;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text.active + .step-item-tab-num-text {
  padding-top: 12px;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text.active::after {
  content: "";
  width: 42px;
  height: 42px;
  background: #FFEFF0;
  -webkit-border-radius: 6px;
          border-radius: 6px;
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 8px;
  left: -10px;
  z-index: -1;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text.active .title {
  color: #f4313f;
  font-weight: 600;
}
.home-training-step .step-item-tab .step-item-tab-num .step-item-tab-num-text.active .title span {
  background: -webkit-linear-gradient(316deg, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(316deg, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(134deg, #FF9678 0%, #F5333F 100%);
}
