.thirds-screen .famous-certificate-box-swiper {
  background: #FFFFFF;
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
}

.thirds-screen .famous-certificate-box-swiper .certificate-card {
  width: 100%;
  padding: 0.32rem 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.thirds-screen .famous-certificate-box-swiper .certificate-card img {
  width: 50%;
}