.development-history-box {
  height: 442px;
  width: 100%;
}
.development-history-box .development-history-contain {
  width: 100%;
  height: 100%;
  position: relative;
}
.development-history-box .development-history-contain .development-history-swiper {
  width: 100%;
  height: 100%;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper {
  width: 288px;
  height: 100%;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper:first-child {
  margin-left: 2px;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper:first-child .item-contain {
  border-left: 1px solid #EEEEEE;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain {
  width: 100%;
  height: 100%;
  position: relative;
  border-right: 1px solid #EEEEEE;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  background: #ffffff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain.active {
  background: #f9f9fb;
  position: relative;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain.active::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 4px;
  background: #F5333F;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain > .time {
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  line-height: 45px;
  padding: 28px 0 0 23px;
  margin-bottom: 32px;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain .details-box-contain {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain .details-box-contain::-webkit-scrollbar {
  display: none;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item {
  position: relative;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item .time {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  padding-left: 24px;
  position: relative;
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item .time::before {
  content: ' ';
  display: block;
  position: absolute;
  left: -2px;
  width: 3px;
  height: 3px;
  background: #333333;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%);
}
.development-history-box .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item .details {
  padding: 18px 23px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
