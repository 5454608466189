.news-information-box-mobile {
  height: 11.306667rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0.213333rem;
  margin-bottom: 0.853333rem;
}

.news-information-box-mobile .news-information-box-swiper-box {
  width: 100%;
  height: 11.306667rem;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 0.266667rem;
  border-radius: 0.266667rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper {
  width: 100%;
  height: 11.306667rem;
  padding: 0.426667rem 0;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-slide {
  width: 8.293333rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-slide .news-information-box-swiper-slide {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-slide.swiper-slide-active .news-information-box-swiper-slide {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-slide.swiper-slide-duplicate-active .news-information-box-swiper-slide {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide {
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0.106667rem 0.32rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.106667rem 0.32rem 0 rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
  border: 0.013333rem solid #EEEEEE;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  transition: 300ms;
  padding: 0.853333rem 0.426667rem 0.426667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide .title {
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide .details {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.56rem;
  margin-top: 0.426667rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 5.6rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide .user-time {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0.4rem;
  border-top: 0.013333rem solid #EEEEEE;
  margin-top: 0.426667rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide .user-time .header-img {
  width: 0.96rem;
  height: 0.96rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 0.213333rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide .user-time .name-time .name {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .news-information-box-swiper-slide .user-time .name-time .time {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.44rem;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #FFFFFF;
  opacity: 0.2;
  margin: 0 0.053333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.news-information-box-mobile .news-information-box-swiper-box .news-information-box-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 0.32rem;
  height: 0.106667rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.053333rem;
  border-radius: 0.053333rem;
  opacity: 1;
}