.honorary-awards-box {
  height: 443px;
  background: -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), to(#FFEFF0));
  background: -webkit-linear-gradient(bottom, #FFFFFF 0%, #FFEFF0 100%);
  background: -moz-linear-gradient(bottom, #FFFFFF 0%, #FFEFF0 100%);
  background: linear-gradient(360deg, #FFFFFF 0%, #FFEFF0 100%);
  -webkit-border-radius: 20px;
          border-radius: 20px;
  border: 1px solid #FFEFF0;
  padding-top: 40px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.honorary-awards-box .title {
  font-weight: 600;
  color: #333333;
  line-height: 33px;
  font-size: 24px;
  padding-left: 64px;
}
.honorary-awards-box .time-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: absolute;
  top: 40px;
  right: 64px;
}
.honorary-awards-box .time-list .time-item {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  width: 45px;
  margin-left: 48px;
  position: relative;
  cursor: pointer;
  text-align: center;
}
.honorary-awards-box .time-list .time-item.active {
  font-weight: 600;
  color: #333333;
}
.honorary-awards-box .time-list .time-item.active::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 16px;
  height: 3px;
  background: #F5333F;
  -webkit-border-radius: 2px;
          border-radius: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -4px;
}
.honorary-awards-box .honorary-awards-list {
  margin-top: 32px;
  width: 100%;
  height: 274px;
}
.honorary-awards-box .honorary-awards-list .honorary-swiper {
  height: 100%;
  width: 100%;
}
.honorary-awards-box .honorary-awards-list .honorary-swiper .swiper-slide-prev {
  margin-left: 64px;
}
.honorary-awards-box .honorary-awards-list .honorary-swiper .honorary-slide {
  width: 250px;
  margin-right: 24px;
}
.honorary-awards-box .honorary-awards-list .honorary-swiper .honorary-slide .awards-item {
  height: 100%;
}
