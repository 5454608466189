.honorary-awards-box-mobile {
  margin-top: 0.32rem;
  margin-bottom: 0.853333rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background: -webkit-gradient(linear, left top, left bottom, from(#F9F9FB), to(#FFFFFF));
  background: -webkit-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, #F9F9FB 0%, #FFFFFF 100%);
}

.honorary-awards-box-mobile .time-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: auto;
}

.honorary-awards-box-mobile .time-list::-webkit-scrollbar {
  display: none;
}

.honorary-awards-box-mobile .time-list .active_line {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -moz-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -moz-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
  width: 0.48rem;
  height: 0.08rem;
  background: #F5333F;
  -webkit-border-radius: 0.04rem;
  border-radius: 0.04rem;
  -webkit-transform: translateX(0.666667rem);
  -moz-transform: translateX(0.666667rem);
  transform: translateX(0.666667rem);
}

.honorary-awards-box-mobile .time-list .time-item {
  position: relative;
  padding: 0 0.32rem;
  height: 1.173333rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  transition: 0.25s all;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.honorary-awards-box-mobile .time-list .time-item:nth-child(2) {
  padding-left: 0.426667rem;
}

.honorary-awards-box-mobile .time-list .time-item:last-child {
  padding-right: 0.426667rem;
}

.honorary-awards-box-mobile .time-list .time-item.active {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.6rem;
}

.honorary-awards-box-mobile .honorary-awards-list {
  padding: 0 0.426667rem;
}

.honorary-awards-box-mobile .honorary-awards-list .list-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.honorary-awards-box-mobile .honorary-awards-list .list-box .list-item {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 4.466667rem;
  height: 5.04rem;
  margin-top: 0.426667rem;
}

.honorary-awards-box-mobile .honorary-awards-list .list-box .list-item:not(:nth-child(2n)) {
  margin-right: 0.213333rem;
}