.idea-item-user-info-mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.idea-item-user-info-mobile .info-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.idea-item-user-info-mobile .info-left .info-header-img {
  width: 1.173333rem;
  height: 1.173333rem;
  overflow: hidden;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 0.013333rem solid #ddd;
}

.idea-item-user-info-mobile .info-left .info-name-other {
  margin-left: 0.213333rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.idea-item-user-info-mobile .info-left .info-name-other .name-wrap {
  margin-bottom: 0.106667rem;
}

.idea-item-user-info-mobile .info-left .info-name-other .name-wrap span {
  font-weight: 500;
  font-size: 0.426667rem;
  color: #1A1A1A;
  line-height: 0.613333rem;
  text-align: left;
}

.idea-item-user-info-mobile .info-left .info-name-other .time-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.idea-item-user-info-mobile .info-left .info-name-other .time-number .levelImgBig {
  width: 2.026667rem;
  height: 0.48rem;
  margin-right: 0.106667rem;
}