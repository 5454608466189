a[title='站长统计'] {
  display: none;
}
.comunity-list-page {
  background-color: #fff;
}
.comunity-list-page .main-container {
  overflow: hidden;
  padding-top: 32px;
}
.comunity-list-page .main-container .main-container-width {
  width: 1200px;
  margin: 0 auto;
}
.comunity-list-page .main-container .community-list-contain::before {
  content: '';
  display: table;
  border-top: 1px solid transparent;
}
.comunity-list-page .main-container .community-list-contain .list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.comunity-list-page .main-container .community-list-contain .list .clockin-box {
  margin-bottom: 32px;
}
.comunity-list-page .main-container .community-list-contain .list .clockin-box:not(:nth-child(5n)) {
  margin-right: 23px;
}
.comunity-list-page .main-container .list-btn {
  width: 192px;
  height: 48px;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(245, 51, 63, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 24px;
          border-radius: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  margin-bottom: 64px;
}
.comunity-list-page .main-container .no-data-box {
  text-align: center;
  margin: 130px auto 194px;
}
.comunity-list-page .main-container .no-data-box img {
  width: 120px;
  height: 120px;
}
.comunity-list-page .main-container .no-data-box .tips {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-top: 8px;
}
