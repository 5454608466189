.homework-data-wrap-mobile {
  margin: 0;
  padding-bottom: 0.64rem;
}

.homework-data-wrap-mobile .homework-line {
  border-top: 0.013333rem solid #EEEEEE;
  margin-bottom: 0.64rem;
}

.homework-data-wrap-mobile.first {
  margin-top: 0.32rem;
}

.homework-data-wrap-mobile .inner-wrap {
  padding: 0.32rem;
  overflow: hidden;
  background: #F7F7F7;
  -webkit-border-radius: 0.106667rem;
  border-radius: 0.106667rem;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap {
  max-height: 1.72rem;
  overflow: hidden;
  position: relative;
  line-height: 0.573333rem;
  font-size: 0.32rem;
  color: #666;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap.height-auto {
  max-height: none;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .title {
  padding: 0 0.213333rem;
  height: 0.533333rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.053333rem 0.053333rem 0.32rem 0.053333rem;
  border-radius: 0.053333rem 0.053333rem 0.32rem 0.053333rem;
  border: 0.013333rem solid #BBBBBB;
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -moz-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.32rem;
  color: #666;
  white-space: nowrap;
  display: inline-block;
  margin-right: 0.213333rem;
  font-weight: bold;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .title > img {
  width: 0.173333rem;
  margin: 0 0.066667rem;
  display: block;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap {
  margin-left: -0.213333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box {
  width: -moz-calc(100% / 3 - 0.213333rem);
  width: calc(100% / 3 - 0.213333rem);
  height: 0;
  padding-top: -moz-calc(100% / 3 - 0.213333rem);
  padding-top: calc(100% / 3 - 0.213333rem);
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  margin-left: 0.213333rem;
  margin-top: 0.213333rem;
  background-color: #000;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box .img-contain {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box .video-play-icon {
  position: absolute;
  width: 0.426667rem;
  height: 0.426667rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 0.213333rem;
  right: 0.213333rem;
  z-index: 1;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding .transcoding-icon {
  width: 0.96rem;
  height: 0.96rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding .transcoding-icon img {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate360 1s infinite linear;
  -moz-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .img-wrap .img-box .video-transcoding .transcoding-tips {
  margin-top: 0.213333rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.44rem;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .control-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.32rem;
  color: #333;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0.573333rem;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .control-btn .point {
  display: block;
  width: 1.066667rem;
  text-align: right;
  background: -webkit-gradient(linear, right top, left top, from(#f7f7f7), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(right, #f7f7f7, rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(right, #f7f7f7, rgba(255, 255, 255, 0));
  background: linear-gradient(to left, #f7f7f7, rgba(255, 255, 255, 0));
  font-size: 0.4rem;
  height: 0.573333rem;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .control-btn .text-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #f7f7f7;
  padding: 0 0.106667rem;
}

.homework-data-wrap-mobile .inner-wrap .question-wrap .control-btn .text-wrap .iconfont {
  font-size: 0.32rem;
  margin-left: 0.026667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.homework-data-wrap-mobile .answer-wrap {
  margin-top: 0.32rem;
}

.homework-data-wrap-mobile .answer-wrap .title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.426667rem;
  font-weight: bold;
  line-height: 0.64rem;
}

.homework-data-wrap-mobile .answer-wrap .title::before {
  content: '';
  display: block;
  width: 0.08rem;
  height: 0.373333rem;
  background: #F5333F;
  -webkit-border-radius: 0 0.053333rem 0.053333rem 0;
  border-radius: 0 0.053333rem 0.053333rem 0;
  margin-right: 0.213333rem;
}

.homework-data-wrap-mobile .answer-wrap .content {
  font-size: 0.373333rem;
  line-height: 0.64rem;
  margin-top: 0.213333rem;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap {
  margin-left: -0.213333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box {
  width: -moz-calc(100% / 3 - 0.213333rem);
  width: calc(100% / 3 - 0.213333rem);
  height: 0;
  padding-top: -moz-calc(100% / 3 - 0.213333rem);
  padding-top: calc(100% / 3 - 0.213333rem);
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  margin-left: 0.213333rem;
  margin-top: 0.213333rem;
  background-color: #000;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box .img-contain {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box .video-play-icon {
  position: absolute;
  width: 0.426667rem;
  height: 0.426667rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 0.213333rem;
  right: 0.213333rem;
  z-index: 1;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box .video-transcoding {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box .video-transcoding .transcoding-icon {
  width: 0.96rem;
  height: 0.96rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box .video-transcoding .transcoding-icon img {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate360 1s infinite linear;
  -moz-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
}

.homework-data-wrap-mobile .answer-wrap .img-wrap .img-box .video-transcoding .transcoding-tips {
  margin-top: 0.213333rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.44rem;
}