.category-module-page {
  width: 100% !important;
}
.category-module-page .main-container-width {
  width: 1200px;
  margin: 0 auto;
}
.category-module-page .category-banner {
  width: 100%;
  height: 814px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 80px;
}
.category-module-page .category-banner .category-banner-img {
  margin-top: 32px;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  height: 400px;
  width: 100%;
}
.category-module-page .category-teacher {
  height: 775px;
}
.category-module-page .category-activity {
  height: 739px;
  background: url(https://img.qlchat.com/qlLive/activity/image/CLQ685QE-K7T7-4VC7-1712457923169-WJ67E7TU4G69.png) center / 100% 100% no-repeat;
}
.category-module-page .category-article {
  height: 850px;
}
.category-module-page .column-title {
  text-align: center;
  padding-top: 80px;
}
.category-module-page .column-title .column-title-img {
  width: auto;
  height: 46px;
}
.category-module-page .column-title .column-title-desc {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 24px;
}
.category-module-page .column-title img {
  width: auto;
  height: 104px;
}
