.home-training-category {
  margin-top: 36px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 32px;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.home-training-category .category-item {
  width: 384px;
  height: 343px;
  background: #ffffff;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  overflow: hidden;
  margin-bottom: 32px;
  position: relative;
}
.home-training-category .category-item:not(:nth-child(3n)) {
  margin-right: 24px;
}
.home-training-category .category-item .category-code {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}
.home-training-category .category-item .category-code .category-code-img {
  width: 170px;
  height: 170px;
}
.home-training-category .category-item .category-code .category-code-tips {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.home-training-category .category-item:hover {
  -webkit-transform: translateY(-20px);
     -moz-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
}
.home-training-category .category-item:hover .category-code {
  opacity: 1;
}
.home-training-category .category-item .category-item-img {
  width: 100%;
  height: 239px;
}
.home-training-category .category-item .category-item-img img {
  width: 100%;
  vertical-align: middle;
}
.home-training-category .category-item .category-item-message {
  padding: 24px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.home-training-category .category-item .category-item-message .message-left .category-item-title {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.home-training-category .category-item .category-item-message .message-left .category-item-tips {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.home-training-category .category-item .category-item-message .message-right .category-item-price {
  font-size: 24px;
  color: #F5333F;
  line-height: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.home-training-category .category-item .category-item-message .message-right .category-item-price span {
  font-size: 14px;
  color: #F5333F;
  line-height: 20px;
  margin-right: 2px;
}
