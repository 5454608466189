.comment-list-box-mobile {
  margin-top: 0;
}

.comment-list-box-mobile .comment-contain .middle-comment-entry-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.333333rem;
}

.comment-list-box-mobile .comment-contain .middle-comment-entry-wrap .comment-icon {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -moz-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 0.533333rem;
  height: auto;
  margin-right: 0.213333rem;
}

.comment-list-box-mobile .comment-contain .middle-comment-entry-wrap .input-box {
  width: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  background: #F7F7F7;
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0.32rem;
  font-size: 0.32rem;
  color: #999;
  cursor: pointer;
  height: 0.853333rem;
}

.comment-list-box-mobile .list-box .comment-list-contain {
  padding-left: 0.746667rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item {
  margin-bottom: 0.453333rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 0;
  margin-right: 0;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-header-img {
  width: 0.853333rem;
  height: 0.853333rem;
  margin-right: 0.213333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  border: 0.013333rem solid #DDDDDD;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  overflow: hidden;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-reply-ctn {
  font-size: 0.373333rem;
  color: #333;
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.053333rem;
  line-height: 0.44rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-reply-ctn .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 2.16rem;
  display: inline-block;
  margin-right: 0.106667rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-reply-ctn .reply-text {
  margin: 0 0.106667rem;
  color: #999;
  font-weight: normal;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-name {
  font-size: 0.346667rem;
  font-weight: 700;
  color: #333333;
  line-height: 0.44rem;
  margin-bottom: 0.106667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .user-name .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 3.066667rem;
  display: inline-block;
  margin-right: 0.106667rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .like-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.32rem;
  color: #666;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .like-box.active {
  color: #F5333F;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .top-wrap .like-box .iconfont {
  font-size: 0.426667rem;
  margin-left: 0.106667rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .user-name-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .user-name-btn .name {
  width: 3.52rem;
  font-size: 0.346667rem;
  font-weight: 700;
  color: #333333;
  line-height: 0.44rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.053333rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .user-name-btn .btn {
  font-size: 0.32rem;
  font-weight: 400;
  color: #BBBBBB;
  line-height: 0.44rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-wrap .spread-btn {
  font-size: 0.32rem;
  color: #F5333F;
  cursor: pointer;
  line-height: 0.44rem;
  margin-top: 0.106667rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-message {
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.44rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-message .GRID_1_4 {
  width: 1.6rem;
  height: 1.6rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-message .GRID_1_7 {
  width: 0.533333rem;
  height: 0.533333rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn {
  margin-top: 0.173333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .time {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.44rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .reply-btn,
.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .reply-box-btn {
  height: 0.533333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 0.213333rem;
  background: #f7f7f7;
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
  font-size: 0.32rem;
  color: #666;
  margin-left: 0.32rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn .left-wrap .reply-box-btn .iconfont {
  font-size: 0.32rem;
}

.comment-list-box-mobile .list-box .comment-list-contain .comment-item .comment-detail-item-wrap .comment-user-message .comment-time-btn > .icongengduo1 {
  font-size: 0.426667rem;
  color: #999;
}

.comment-list-box-mobile .list-box .more-btn {
  margin: 0 auto;
  margin-top: 0.453333rem;
  margin-bottom: 0.453333rem;
  width: 3.96rem;
  height: 0.853333rem;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0 0.133333rem 0.266667rem 0 rgba(245, 51, 63, 0.2);
  box-shadow: 0 0.133333rem 0.266667rem 0 rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.346667rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.493333rem;
}

.comment-list-box-mobile .no-data-box {
  text-align: center;
  margin: 0.32rem 0 0.853333rem;
}

.comment-list-box-mobile .no-data-box .no-data-img {
  width: 3.2rem;
  height: 3.2rem;
}

.comment-list-box-mobile .no-data-box .no-data-tips {
  margin-top: 0.106667rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.266667rem;
}