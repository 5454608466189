a[title='站长统计'] {
  display: none;
}
.fadeInUp {
  -webkit-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
     -moz-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
          animation: fadeInUp 0.3s ease-in-out 0s 1 both;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 32px, 0);
            transform: translate3d(0, 32px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 32px, 0);
         transform: translate3d(0, 32px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
         transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 32px, 0);
       -moz-transform: translate3d(0, 32px, 0);
            transform: translate3d(0, 32px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
         transform: none;
  }
}
.home-training-page {
  background-color: #fff;
}
.home-training-page .main-container {
  overflow: hidden;
}
.home-training-page .main-container .main-container-brand {
  background: url(https://img.qlchat.com/qlLive/activity/image/5H2MAPW6-XGEK-HTW3-1708583820033-KQ8RT39U8757.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
  width: 100%;
  height: 1258px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.home-training-page .main-container .main-container-honorary {
  background: url(https://img.qlchat.com/qlLive/activity/image/3KFS9PQO-MNNF-3Z5V-1708933436587-OHHVZYHMZDB1.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
  width: 100%;
  height: 826px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.home-training-page .main-container .main-container-brand-report {
  background: url(https://img.qlchat.com/qlLive/activity/image/K3J6NHAX-CUEN-RAXY-1709893952992-29RFP2W384US.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
  width: 100%;
  height: 736px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 35px;
}
.home-training-page .main-container .main-container-bg-1 {
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F9F9FB));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: -moz-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9FB 100%);
}
.home-training-page .main-container .main-container-bg-1 .home-training-title {
  margin-top: 0;
  padding-top: 64px;
}
.home-training-page .main-container .main-container-width {
  width: 1200px;
  margin: 0 auto;
}
.home-training-page .main-container .home-middle-banner {
  width: 1202px;
  height: 133px;
  margin: 0 auto 0px;
}
.home-training-page .main-container .home-middle-banner img {
  width: 100%;
  height: 100%;
}
.home-training-page .main-container .home-training-grey-bg {
  background: #F9F9FB;
  padding-top: 64px;
}
.home-training-page .main-container .home-training-grey-bg::before {
  content: '';
  display: table;
  border-top: 1px solid transparent;
}
.home-training-page .main-container .home-training-grey-bg1 {
  background: #f9f9fb;
}
.home-training-page .main-container .home-training-grey-bg1::before {
  content: '';
  display: table;
  border-top: 1px solid transparent;
}
.home-training-page .main-container .home-training-grey-bg4 {
  background: -webkit-gradient(linear, left top, left bottom, from(#F9F9FB), to(#FFFFFF));
  background: -webkit-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, #F9F9FB 0%, #FFFFFF 100%);
  padding-bottom: 64px;
}
.home-training-page .main-container .home-training-grey-bg4::before {
  content: '';
  display: table;
  border-top: 1px solid transparent;
}
.home-training-page .main-container .home-training-grey-bg2 {
  background: #fff;
}
.home-training-page .main-container .home-training-grey-bg2::before {
  content: '';
  display: table;
  border-top: 1px solid transparent;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  padding-top: 64px;
  padding-bottom: 16px;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-list .tab-item {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  cursor: pointer;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-list .tab-item.active {
  font-size: 20px;
  font-weight: 600;
  color: #F5333F;
  line-height: 28px;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-acitve-line {
  background: #f7f7f7;
  background: -webkit-gradient(linear, left top, right top, from(rgba(247, 247, 247, 0.3)), color-stop(#f7f7f7), to(rgba(247, 247, 247, 0.3)));
  background: -webkit-linear-gradient(left, rgba(247, 247, 247, 0.3), #f7f7f7, rgba(247, 247, 247, 0.3));
  background: -moz-linear-gradient(left, rgba(247, 247, 247, 0.3), #f7f7f7, rgba(247, 247, 247, 0.3));
  background: linear-gradient(90deg, rgba(247, 247, 247, 0.3), #f7f7f7, rgba(247, 247, 247, 0.3));
  height: 5px;
  position: relative;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-acitve-line .tab-active-circle {
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 4px solid #F5333F;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  top: -5.5px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-acitve-line .tab-active-circle.active0 {
  left: 140px;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-acitve-line .tab-active-circle.active1 {
  left: 440px;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-acitve-line .tab-active-circle.active2 {
  left: 740px;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab .tab-acitve-line .tab-active-circle.active3 {
  left: 1040px;
}
.home-training-page .main-container .home-training-grey-bg2 .home-training-ql-tab-contain {
  margin: 37px auto 64px;
}
.home-training-page .main-container .home-training-honoray-awards {
  background: #fff;
  margin-bottom: 64px;
}
.home-training-page .main-container .home-training-honoray-awards::before {
  content: '';
  display: table;
  border-top: 1px solid transparent;
}
.home-training-page .main-container .home-training-title {
  text-align: center;
  padding-top: 80px;
}
.home-training-page .main-container .home-training-title .home-training-title-img {
  width: auto;
  height: 46px;
}
.home-training-page .main-container .home-training-title .home-training-title-desc {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 24px;
}
.home-training-page .main-container .home-training-title img {
  width: auto;
  height: 104px;
}
.home-training-page .main-container .home-training-student {
  margin-top: 36px;
  padding-bottom: 26px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-container {
  width: 100%;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-button-prev,
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-button-next {
  width: 48px;
  height: 48px;
  background: #ddd;
  color: #fff;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  opacity: 1 !important;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-button-prev::after,
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-button-next {
  right: 0;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-slide {
  margin-right: 64px;
  width: 338px !important;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-slide.swiper-slide-active {
  margin-left: 64px;
  margin-right: 29px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .swiper-slide.swiper-slide-next {
  margin-right: 29px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item {
  width: 338px;
  height: 390px;
  background: #fff;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  padding: 24px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-user {
  padding-left: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-user .item-user-img {
  width: 60px;
  height: 64px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-user .item-user-img img {
  width: 100%;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-user .item-user-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin-left: 12px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-user .item-user-content .item-user-name {
  font-size: 24px;
  color: #333333;
  line-height: 33px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-user .item-user-content .item-user-tip {
  font-size: 14px;
  color: #f4313f;
  line-height: 20px;
}
.home-training-page .main-container .home-training-student .home-training-studen-banne-swiper .studen-banne-swiper-item .studen-banne-swiper-item-detail {
  margin-top: 20px;
  width: 290px;
  height: 254px;
  background: #f9f9fb;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  padding: 16px 20px 0;
  font-size: 16px;
  color: #333333;
  line-height: 28px;
}
