.m-not-fount {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.m-not-fount > .tips {
  margin-top: 0.426667rem;
  font-size: 0.32rem;
  color: #b2b2b2;
}

.m-not-fount > .back-btn {
  margin-top: 0.426667rem;
  font-size: 0.426667rem;
  color: #f73657;
  width: 4.266667rem;
  height: 1.013333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0.013333rem solid #f73657;
  -webkit-border-radius: 0.666667rem;
  border-radius: 0.666667rem;
}