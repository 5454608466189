.clock-in-item-mobile {
  width: 4.466667rem;
  height: 8rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.266667rem;
  border-radius: 0.266667rem;
  border: 0.013333rem solid #EEEEEE;
  overflow: hidden;
  cursor: pointer;
}

.clock-in-item-mobile .clock-img-box {
  position: relative;
  height: 5.946667rem;
  width: 100%;
  overflow: hidden;
}

.clock-in-item-mobile .clock-img-box img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.clock-in-item-mobile .clock-img-box .video-play-cion {
  position: absolute;
  width: 0.426667rem;
  height: 0.426667rem;
  top: 0.213333rem;
  right: 0.213333rem;
  display: block;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/BE3ODLGM-YPY9-H7FF-1653387966274-43XXRM41MGUH.png?x-oss-process=image/format,webp");
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100%;
}

.clock-in-item-mobile .content-message {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 0.213333rem;
  margin: 0.213333rem 0;
  min-height: 0.586667rem;
  font-weight: 400;
  word-break: break-all;
  font-size: 0.346667rem;
  color: #333333;
  line-height: 0.493333rem;
  height: 0.986667rem;
}

.clock-in-item-mobile .user-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.213333rem;
}

.clock-in-item-mobile .user-message .user-header-img {
  width: 0.426667rem;
  height: 0.426667rem;
  border: 0.013333rem solid #DDDDDD;
  overflow: hidden;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  margin-right: 0.106667rem;
}

.clock-in-item-mobile .user-message .user-header-img img {
  width: 100%;
  height: 100%;
}

.clock-in-item-mobile .user-message .user-name {
  width: 3.186667rem;
  height: 0.4rem;
  font-size: 0.293333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}