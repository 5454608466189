.p-course-item {
  padding: 12px 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.p-course-item .item-poster {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 100%;
  padding-top: 60%;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 8px;
          border-radius: 8px;
}
.p-course-item .item-poster .ql-feat-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  overflow: hidden;
}
.p-course-item .item-poster:hover .ql-feat-picture {
  -webkit-animation: sca linear 0.5s;
     -moz-animation: sca linear 0.5s;
          animation: sca linear 0.5s;
  -webkit-animation-fill-mode: forwards;
     -moz-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes sca {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@-moz-keyframes sca {
  from {
    -moz-transform: scale(1);
         transform: scale(1);
  }
  to {
    -moz-transform: scale(1.1);
         transform: scale(1.1);
  }
}
@keyframes sca {
  from {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
       -moz-transform: scale(1.1);
            transform: scale(1.1);
  }
}
.p-course-item .item-title {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin-top: 12px;
  color: #2b3940;
  font-size: 16px;
  line-height: 1.4;
}
.p-course-item .item-title:hover {
  color: #f73657;
}
.p-course-item .item-desc {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  margin-top: 24px;
  color: rgba(43, 57, 64, 0.65);
  font-size: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.p-course-item .p-play-btn {
  width: 80px;
  height: 32px;
  background: -webkit-linear-gradient(135deg, #f73657 0%, #f97e69 100%);
  background: -moz-linear-gradient(135deg, #f73657 0%, #f97e69 100%);
  background: linear-gradient(315deg, #f73657 0%, #f97e69 100%);
  -webkit-border-radius: 20px;
          border-radius: 20px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}
.p-course-item .p-play-btn:hover::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
}
.p-course-item .p-play-btn::before {
  content: '';
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAPxJREFUSA1jYBg24P///4xAnA7Eh4B4OxBnADEr1TwINKwCiNHBTaCAD1UsARr0DN10JP4uIFubbIuAmtmRDMPF/AOUmArEwiRbBNREjAUwi98DGQVATHz8kGgBzCLi44dMC2AWEY4fCi0AWQSKny4gZsIaP0AJUuIAqBwnaEC3gBEkAFTODqR+oEuSwX/IyMiogKwPu5eQVZDG/o+unNoWzEG3AMwHBRHOUCVOAhTJrVgNBwlSaAHNkinNMhqoqCgEYqoXFbDCTgRnWOOTALroNhDjAoTDGZ/hIDmgyaVYTCc+nAlZALUkHWjJESAGVZtZQEx8OBNjwZBUAwD2NT0WOAvjGwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
          background-size: cover;
}
.p-course-item .p-play-btn.loading::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAArxJREFUSA2tlk1sTFEUgOeN+q3/CoLFbCRNVCJCiO0kGrGykdjYChI7YWthY21j3wh7Kz9NkNA1QtKw8JPoaCtCKGX6fN945/VVX/EmTvL13J9zz7n33HPfNKn9QdI07WN6P+yFbbABlsIkjMEI3E+SpIUulaRsFMfrGT8KTVgM2i0p6DrtHlAvghtwhUDv0HNkXgCc78biFKwEHRhAHQE8QQQszn9n/DxBhtG5aJALzg/SOQM6SWEGFNuiqIsb04f9XriMj+PoXHJDJnYxeho8euxQba7vwCMYB2UT7IMj0A9xklh3gpPcYvzXTnC+lvYFWAaRCpq1q3Ab4ziJY7mwTsfe1TlYDhHoC+0m68YcUA6BudbRN5iCSxjcXMg58zXn4BrNk3bBzXnpa+As1OrZ7j2uzsUcX2fhU/Q/CbYjGF7MjA3gxo/he4uNAWiDVSCv4R5UlSEWvAQzIabssAG8pNi9+oFHR1eSbI135glEGbRizJcn+AHm8Rl0K3dZGEWir+0G8EEZwAHz/wG6lVcsfAPhq20A8+7A/5CvOBksOEoN8An89sRLXU07HhTNSqIPiyQXA7TAZ65o0IBuA1g9Fo5+zErbzotsIE4wQP1WTlm2xgvWZ1TRjJ1R8B58weZwFeyEqmLdrwA/lAbys5PWqV/v4DGEWFEH2NHWGPibxlbnlrsnj3cw7dvwBMpD+AzT4HvwoTVZ2J8dne58cQ7WMeOvnbt2996Dd2tGZssTwwZ9S0zn4knUE/Ac3oIfQUVHvh+rT9HOzand4CS7N92zAewQxO/SHpvgvbggtDsyqI7UOnA+dNiN4/wj4x2JFHU6TDyhMQyRJgP9Tse28EcfYv5bRefalJYjJ7EadkAD3JlBYuf24ySewPHOfxk4d26OlAYIiyzQZvobwUv0AnVoukyD3633OHasVH4Csunj1HTcejgAAAAASUVORK5CYII=);
}
.p-course-item .p-play-btn.playing::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAI5JREFUSA3tVUEOgCAMAw+8nXf4OHwGdgkYLItD48EYlixsS9tpNercbyLnHJARmUpKHawbFAzS5hUQjlPEgQUiztHzgJAr50gDCy55vgqIcq3b0yPanmuLtzDh7X4uMB2dFk2LTAdMwHyLblm0KWhtxjANc8zaZ7AyE702Y5iG6Wf4ro/9+kj+KY9kPtzuC/i+/CV59/0AAAAASUVORK5CYII=);
}
