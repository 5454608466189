.second-screen .second-screen-tab-box {
  width: 6.88rem;
  height: 1.013333rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.08rem;
  background: #F8F8FB;
  -webkit-border-radius: 1.2rem;
  border-radius: 1.2rem;
  margin: 0 auto 0.72rem;
}

.second-screen .second-screen-tab {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 1.2rem;
  border-radius: 1.2rem;
  background: #F8F8FB;
}

.second-screen .second-screen-tab .tab-switch-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 0;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.4rem;
  color: #1A1A1A;
}

.second-screen .second-screen-tab .active {
  color: #F5333F;
}

.second-screen .second-screen-tab .active::after {
  display: none;
}

.second-screen .second-screen-tab .tab-active {
  width: -moz-calc((1 / 3) * 100%);
  width: calc((1 / 3) * 100%);
  background-color: #fff;
  height: 100%;
  -webkit-border-radius: 1.2rem;
  border-radius: 1.2rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  transition: left 0.3s;
}

.second-screen .tab-1 {
  padding: 0.133333rem;
  background: -webkit-linear-gradient(315deg, rgba(255, 243, 234, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: -moz-linear-gradient(315deg, rgba(255, 243, 234, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(135deg, rgba(255, 243, 234, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-box-shadow: 0 0.066667rem 0.84rem 0.133333rem rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.066667rem 0.84rem 0.133333rem rgba(252, 222, 212, 0.42);
  border: 0.026667rem solid #fff;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
}

.second-screen .tab-1 img {
  width: 100%;
}

.second-screen .tab-2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 0.266667rem;
  border-radius: 0.266667rem;
}

.second-screen .tab-2 .swiper-container {
  overflow: visible;
}

.second-screen .tab-2 .famous-teacher-box-swiper {
  width: 100%;
  height: 5.226667rem;
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide {
  width: 3.52rem;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide.swiper-slide-next {
  -webkit-transform: translateX(-0.666667rem) translateY(0) perspective(8rem) rotateY(-20deg);
  -moz-transform: translateX(-0.666667rem) translateY(0) perspective(8rem) rotateY(-20deg);
  transform: translateX(-0.666667rem) translateY(0) perspective(8rem) rotateY(-20deg);
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide.swiper-slide-prev {
  -webkit-transform: translateX(0.666667rem) translateY(0) perspective(8rem) rotateY(20deg);
  -moz-transform: translateX(0.666667rem) translateY(0) perspective(8rem) rotateY(20deg);
  transform: translateX(0.666667rem) translateY(0) perspective(8rem) rotateY(20deg);
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide.swiper-slide-active {
  z-index: 10;
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide.swiper-slide-active .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  margin: 0;
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-slide.swiper-slide-duplicate-active .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  margin: 0;
}

.second-screen .tab-2 .famous-teacher-box-swiper .famous-teacher-box-swiper-slide {
  height: 100%;
  width: 100%;
  -webkit-box-shadow: 0 0.213333rem 0.426667rem 0 rgba(245, 51, 63, 0.1);
  box-shadow: 0 0.213333rem 0.426667rem 0 rgba(245, 51, 63, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #FFFFFF;
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
  border: 0.013333rem solid #EEEEEE;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  transition: 300ms;
}

.second-screen .tab-2 .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .advantages-img {
  width: 100%;
  height: 100%;
}

.second-screen .tab-2 .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .advantages-img img {
  width: 100%;
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #FFFFFF;
  opacity: 0.2;
  margin: 0 0.053333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.second-screen .tab-2 .famous-teacher-box-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 0.32rem;
  height: 0.106667rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.053333rem;
  border-radius: 0.053333rem;
  opacity: 1;
}

.second-screen .tab-2 .tab-2-guideline {
  width: 100%;
  height: 5.493333rem;
  background: url(https://media.qianliaowang.com/qlLive/activity/image/LUAS178L-8H89-L7IX-1723005806103-RVJ2HFTGBMSG.png) no-repeat;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: 0.426667rem;
  padding: 0.426667rem 0 0.426667rem 2.106667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.second-screen .tab-2 .tab-2-guideline .guideline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.second-screen .tab-2 .tab-2-guideline .guideline img {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-right: 0.213333rem;
}

.second-screen .tab-2 .tab-2-guideline .guideline p {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #1A1A1A;
  line-height: 0.586667rem;
}

.second-screen .tab-3 .famous-student-box-swiper .swiper-slide {
  width: 100%;
  margin: 0 0.426667rem;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.second-screen .tab-3 .famous-student-box-swiper .swiper-slide .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

.second-screen .tab-3 .famous-student-box-swiper .student-card {
  width: 100%;
  height: 5.973333rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  margin-bottom: 0.426667rem;
  -webkit-border-radius: 0.426667rem 0.426667rem 0.426667rem 0;
  border-radius: 0.426667rem 0.426667rem 0.426667rem 0;
  padding: 0.426667rem;
}

.second-screen .tab-3 .famous-student-box-swiper .student-card.last {
  margin-bottom: 0;
}

.second-screen .tab-3 .famous-student-box-swiper .student-card .student-card-contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.second-screen .tab-3 .famous-student-box-swiper .student-card .student-card-contain img {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.32rem;
}

.second-screen .tab-3 .famous-student-box-swiper .student-card .student-card-contain .student-card-info .title {
  font-weight: 400;
  font-size: 0.4rem;
  color: #1A1A1A;
  line-height: 0.64rem;
  margin-bottom: 0.106667rem;
}

.second-screen .tab-3 .famous-student-box-swiper .student-card .student-card-contain .student-card-info .name {
  padding: 0 0.213333rem;
  background: #F34242;
  -webkit-border-radius: 0.32rem 0.32rem 0.32rem 0;
  border-radius: 0.32rem 0.32rem 0.32rem 0;
  border: 0.026667rem solid #FEE2E4;
  font-size: 0.4rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.64rem;
}

.second-screen .tab-3 .famous-student-box-swiper .student-card .student-card-desc {
  margin-top: 0.32rem;
  font-size: 0.346667rem;
  color: #4D4D4D;
  line-height: 0.533333rem;
  text-align: left;
}

.second-screen .tab-3 .famous-student-box-swiper .swiper-pagination {
  bottom: 0.773333rem;
}

.second-screen .tab-3 .famous-student-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.213333rem;
  height: 0.08rem;
  background: rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 1.08rem;
  border-radius: 1.08rem;
  opacity: 1;
}

.second-screen .tab-3 .famous-student-box-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #F5333F;
}

.second-screen .second-screen-empty {
  height: 1.173333rem;
  background: transparent;
}