.more-courses-mobile {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.more-courses-mobile .main-container .main-container-bg {
  padding: 2.56rem 0.426667rem 0;
  background: url(https://img.qlchat.com/qlLive/activity/image/VX3DSFSC-6YBJ-8IT7-1709792842803-CJZZW8BZUZRH.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.more-courses-mobile .main-container .category-module-mobile-title {
  margin-bottom: 0.266667rem;
  text-align: center;
}

.more-courses-mobile .main-container .category-module-mobile-title img {
  height: 0.733333rem;
}

.more-courses-mobile .main-container .category-module-mobile-desc {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #4D4D4D;
  line-height: 0.586667rem;
  margin-bottom: 0.64rem;
}

.more-courses-mobile .more-courses-tab {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.32rem;
  margin-bottom: 0.32rem;
  background-color: transparent;
}

.more-courses-mobile .more-courses-tab .tab-switch-item {
  text-align: center;
  font-weight: 500;
  font-size: 0.4rem;
  color: #1A1A1A;
  line-height: 0.64rem;
}

.more-courses-mobile .more-courses-tab .tab-switch-item.active {
  color: #F5333F;
  font-size: 0.4rem;
  font-weight: 500;
}

.more-courses-mobile .more-courses-tab .tab-switch-item::after {
  width: 0.32rem;
  height: 0.08rem;
  background: #F5333F;
  -webkit-border-radius: 6.92rem;
  border-radius: 6.92rem;
  bottom: 0.106667rem;
}

.more-courses-mobile .more-courses-content {
  margin: 0 -0.426667rem;
  padding: 0 0.32rem;
}

.more-courses-mobile .more-courses-content .more-courses-content-item {
  width: 100%;
  padding: 0.533333rem 0.64rem 0.746667rem;
  margin-top: -0.48rem;
  background: url(https://img.qlchat.com/qlLive/activity/image/X4UPJOZ8-6XWB-XPZO-1709793133306-167PDAJCGSMA.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.more-courses-mobile .more-courses-content .more-courses-content-item img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  margin-bottom: 0.213333rem;
}

.more-courses-mobile .more-courses-content .more-courses-content-item .more-courses-content-item-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.more-courses-mobile .more-courses-content .more-courses-content-item .more-courses-content-item-content .more-courses-content-item-title {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #1A1A1A;
  line-height: 0.586667rem;
  text-align: left;
}

.more-courses-mobile .more-courses-content .more-courses-content-item .more-courses-content-item-content .more-courses-content-item-label {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #F5333F;
  line-height: 0.586667rem;
  text-align: right;
}