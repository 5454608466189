.development-history-box-mobile {
  height: 10rem;
  width: 100%;
  margin-top: 0.64rem;
  margin-bottom: 0.853333rem;
}

.development-history-box-mobile .development-history-contain {
  width: 100%;
  height: 100%;
  padding-left: 0.426667rem;
  position: relative;
}

.development-history-box-mobile .development-history-contain .development-history-swiper {
  width: 100%;
  height: 100%;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper {
  width: 5.853333rem;
  height: 100%;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper:first-child {
  margin-left: 0.026667rem;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper:first-child .item-contain {
  border-left: 0.013333rem solid #EEEEEE;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain {
  width: 100%;
  height: 100%;
  position: relative;
  border-right: 0.013333rem solid #EEEEEE;
  border-top: 0.013333rem solid #EEEEEE;
  border-bottom: 0.013333rem solid #EEEEEE;
  background: #ffffff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain.active {
  background: #f9f9fb;
  position: relative;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain.active::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 0.053333rem;
  background: #F5333F;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain > .time {
  padding: 0.64rem 0 0 0.426667rem;
  margin-bottom: 0.64rem;
  font-size: 0.64rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.893333rem;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain .details-box-contain {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain .details-box-contain::-webkit-scrollbar {
  display: none;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item {
  position: relative;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item .time {
  font-size: 0.32rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.44rem;
  padding-left: 0.426667rem;
  position: relative;
  margin-bottom: 0.426667rem;
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item .time::before {
  content: ' ';
  display: block;
  position: absolute;
  left: -0.04rem;
  width: 0.066667rem;
  height: 0.066667rem;
  background: #333333;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.development-history-box-mobile .development-history-contain .development-history-swiper .item-swiper .item-contain .details-item .details {
  padding: 0 0.426667rem 0.64rem;
  font-size: 0.293333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.4rem;
}