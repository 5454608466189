.fourth-screen {
  padding-top: 0.64rem;
}

.fourth-screen .swiper-wrapper {
  width: -moz-calc(7.36rem + 0.64rem);
  width: calc(7.36rem + 0.64rem);
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide {
  padding: 0 0.32rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card-box {
  width: 7.36rem;
  height: 8.8rem;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(245, 243, 244, 0.6)), to(#FFFFFF));
  background: -webkit-linear-gradient(top, rgba(245, 243, 244, 0.6) 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, rgba(245, 243, 244, 0.6) 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, rgba(245, 243, 244, 0.6) 0%, #FFFFFF 100%);
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.013333rem solid #FFFFFF;
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  overflow: hidden;
  margin-bottom: 1.146667rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card {
  padding: 0.48rem 0.64rem;
  background-image: url(https://img.qlchat.com/qlLive/activity/image/OARMRUR8-N9M2-JYD6-1709186917017-M74KVO3HN9YK.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card .news-card-img {
  width: 4rem;
  height: 1.28rem;
  margin-bottom: 0.32rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card .news-card-title {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 0.4rem;
  color: #1A1A1A;
  line-height: 0.64rem;
  text-align: left;
  margin-bottom: 0.32rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card .news-card-desc {
  height: 3.733333rem;
  font-weight: 400;
  font-size: 0.346667rem;
  color: #4D4D4D;
  line-height: 0.533333rem;
  text-align: left;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card .news-card-btn {
  position: absolute;
  font-weight: 400;
  font-size: 0.346667rem;
  color: #F5333F;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0.64rem;
  bottom: 0.533333rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card .news-card-btn span {
  margin-right: 0.106667rem;
}

.fourth-screen .news-information-box-swiper .news-information-box-swiper-slide .news-card .news-card-btn i {
  font-size: 0.266667rem;
}