.company-intro-box {
  height: 507px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 10px 30px 0px #EDEDF2;
          box-shadow: 0px 10px 30px 0px #EDEDF2;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  padding: 48px 36px 48px 64px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/7AGWSYRO-JSOK-UQQ1-1653982841822-K782S5AOBYWT.png?x-oss-process=image/format,webp");
  background-repeat: no-repeat;
  -webkit-background-size: 77px 36px;
          background-size: 77px 36px;
  background-position: 1091px 32px;
}
.company-intro-box .company-intro-box-swiper-box {
  width: 548px;
  height: 411px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 20px;
          border-radius: 20px;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper {
  width: 100%;
  height: 411px;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-prev,
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-next {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  opacity: 1 !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-prev::after,
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-next::after {
  font-size: 12px;
  font-weight: bold;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-prev {
  -webkit-transform: translate(-100%, 0%);
     -moz-transform: translate(-100%, 0%);
          transform: translate(-100%, 0%);
  left: 0;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-next {
  -webkit-transform: translate(100%, 0%);
     -moz-transform: translate(100%, 0%);
          transform: translate(100%, 0%);
  right: 0;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper:hover .swiper-button-prev {
  -webkit-transform: translate(50%, 0%);
     -moz-transform: translate(50%, 0%);
          transform: translate(50%, 0%);
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper:hover .swiper-button-next {
  -webkit-transform: translate(-50%, 0%);
     -moz-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .company-intro-box-swiper-img {
  width: 100%;
  height: 100%;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 16px;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  opacity: 0.2;
  margin: 0 4px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}
.company-intro-box .company-intro-box-swiper-box .company-intro-box-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 24px;
  height: 8px;
  background: #FFFFFF;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  opacity: 1;
}
.company-intro-box .company-intro-box-intro-message {
  margin-left: 48px;
  word-wrap: break-all;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.company-intro-box .company-intro-box-intro-message .title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  line-height: 33px;
}
.company-intro-box .company-intro-box-intro-message .line {
  margin-top: 16px;
  width: 20px;
  height: 6px;
  background: #F5333F;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  margin-bottom: 16px;
}
.company-intro-box .company-intro-box-intro-message .details {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
