.famous-teacher-box {
  height: 13.066667rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0.213333rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box {
  width: 100%;
  height: 12.64rem;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 0.266667rem;
  border-radius: 0.266667rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper {
  width: 100%;
  height: 12.64rem;
  padding: 0.426667rem 0;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-slide {
  width: 8.293333rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-slide .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-slide.swiper-slide-active .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-slide.swiper-slide-duplicate-active .famous-teacher-box-swiper-slide {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide {
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0.106667rem 0.32rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.106667rem 0.32rem 0 rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
  border: 0.013333rem solid #EEEEEE;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  transition: 300ms;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/66X1E9LG-T77A-Y19X-1658833153939-DVMVQTTFENZK.png?x-oss-process=image/format,webp");
  background-repeat: no-repeat;
  -webkit-background-size: 7.92rem 4.506667rem;
  background-size: 7.92rem 4.506667rem;
  background-position: 0.4rem 0.653333rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain .advantages-img {
  width: 100%;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain .advantages-img img {
  width: 100%;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain .message {
  padding: 0.426667rem 0.453333rem 0;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain .message .name {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.6rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain .message .intro {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
  margin-top: 0.106667rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .famous-teacher-box-swiper-slide .famous-teacher-contain .message .detail {
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.573333rem;
  margin-top: 0.32rem;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #FFFFFF;
  opacity: 0.2;
  margin: 0 0.053333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.famous-teacher-box .famous-teacher-box-swiper-box .famous-teacher-box-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 0.32rem;
  height: 0.106667rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.053333rem;
  border-radius: 0.053333rem;
  opacity: 1;
}