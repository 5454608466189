.p-course-recommend {
  margin-top: 14px;
}
.p-course-recommend > div:not(:first-of-type) {
  margin-top: 13px;
}
.p-course-recommend > div .item-poster {
  width: 96px;
  height: 66px;
  margin-right: 8px;
}
.p-course-recommend > div .item-title {
  font-size: 16px;
  color: rgba(43, 57, 64, 0.65);
}
