.introduction-container-pc {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
  padding: 24px;
}
.introduction-container-pc .introduction-title {
  padding-bottom: 12px;
  font-size: 44px;
  line-height: 50px;
  font-weight: bold;
  text-align: center;
}
.introduction-container-pc .introduction-secondary-title {
  padding: 24px 0;
  font-size: 36px;
  font-weight: 600;
  color: #333333;
  line-height: 50px;
}
.introduction-container-pc .introduction-third-title {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  line-height: 50px;
}
.introduction-container-pc .introduction-content {
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
}
