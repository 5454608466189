.p-tab-bar {
  margin-top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
          border-radius: 4px;
}
.p-tab-bar li {
  padding: 20px 0;
  color: #2b3940;
  font-size: 16px;
  cursor: pointer;
}
.p-tab-bar li:not(:first-of-type) {
  margin-left: 100px;
}
.p-tab-bar li.act {
  font-weight: bold;
  color: #2b3940;
  position: relative;
}
.p-tab-bar li.act::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: #f73657;
  height: 3px;
  width: 80%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-border-radius: 6px;
          border-radius: 6px;
}
