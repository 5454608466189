.product-advantages-box-mobile {
  height: 10.346667rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0.426667rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box {
  width: 100%;
  height: 9.493333rem;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 0.266667rem;
  border-radius: 0.266667rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper {
  width: 100%;
  height: 9.493333rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide-contain {
  padding: 0 0.106667rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide {
  padding: 0.213333rem 0.32rem;
  background-image: url('https://img.qlchat.com/qlLive/activity/image/UN16L4F9-8DCY-Z35M-1658826835757-3ZBEJSQUW2GY.png?x-oss-process=image/format,webp');
  -webkit-background-size: 100% 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-border-radius: 0.32rem;
  border-radius: 0.32rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .advantages-img {
  width: 100%;
  height: 5.4rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .advantages-img img {
  width: 100%;
  height: 100%;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .message {
  padding: 0.426667rem 0.293333rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .message .title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #F5333F;
  line-height: 0.6rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .message .title > img {
  width: 0.826667rem;
  height: 0.853333rem;
  margin-right: 0.08rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .message .title > span {
  margin-top: -0.133333rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .product-advantages-box-swiper-slide .product-advantages-contain .message .desc {
  padding: 0 0.133333rem;
  font-size: 0.36rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.666667rem;
  margin-top: 0.133333rem;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #bbbbbb;
  opacity: 0.2;
  margin: 0 0.053333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.product-advantages-box-mobile .product-advantages-box-swiper-box .product-advantages-box-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 0.32rem;
  height: 0.106667rem;
  background: #f5333f;
  -webkit-border-radius: 0.053333rem;
  border-radius: 0.053333rem;
  opacity: 1;
}