.p-course-live-info {
  margin-top: 12px;
  font-size: 16px;
}
.p-course-live-info a {
  color: #2b3940;
}
.p-course-live-info .live-poster {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.p-course-live-info .live-ext {
  margin-top: 8px;
  color: rgba(43, 57, 64, 0.65);
  font-size: 12px;
}
.p-course-live-info .live-ext > span:not(:first-of-type) {
  margin-left: 50px;
}
