.p-course-evaluate > li {
  padding: 24px 0;
  border-bottom: 1px solid rgba(43, 57, 64, 0.1);
}
.p-course-evaluate .evaluate-user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  color: rgba(43, 57, 64, 0.85);
  font-size: 14px;
}
.p-course-evaluate .evaluate-user .user-poster {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}
.p-course-evaluate .evaluate-user .evaluate-time {
  position: absolute;
  right: 0;
  color: rgba(43, 57, 64, 0.45);
  font-size: 12px;
}
.p-course-evaluate .evaluate-score {
  margin-top: 16px;
  color: #ffa82a;
  font-size: 14px;
}
.p-course-evaluate .evaluate-content {
  margin-top: 16px;
  color: rgba(43, 57, 64, 0.85);
  font-size: 14px;
  line-height: 1.4;
}
.p-course-evaluate .evaluate-attr {
  margin-top: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: rgba(43, 57, 64, 0.45);
  font-size: 12px;
}
