.company-intro-box-mobile {
  background-color: #FFFFFF;
  padding-bottom: 0.853333rem;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F9F9FB));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: -moz-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9FB 100%);
}

.company-intro-box-mobile .company-intro-box-swiper-box {
  height: 7.493333rem;
  overflow: hidden;
  position: relative;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper {
  width: 100%;
  height: 7.493333rem;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-prev,
.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-next {
  width: 0.64rem;
  height: 0.64rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  opacity: 1 !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-prev::after,
.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-next::after {
  font-size: 0.16rem;
  font-weight: bold;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-prev {
  -webkit-transform: translate(-100%, 0%);
  -moz-transform: translate(-100%, 0%);
  transform: translate(-100%, 0%);
  left: 0;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-button-next {
  -webkit-transform: translate(100%, 0%);
  -moz-transform: translate(100%, 0%);
  transform: translate(100%, 0%);
  right: 0;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper:hover .swiper-button-prev {
  -webkit-transform: translate(50%, 0%);
  -moz-transform: translate(50%, 0%);
  transform: translate(50%, 0%);
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper:hover .swiper-button-next {
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .company-intro-box-swiper-img {
  width: 100%;
  height: 100%;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-pagination {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 0.213333rem;
  z-index: 10;
  -webkit-transition: 0.3s opacity;
  -moz-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #FFFFFF;
  opacity: 0.2;
  margin: 0 0.053333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}

.company-intro-box-mobile .company-intro-box-swiper-box .company-intro-box-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 0.32rem;
  height: 0.106667rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.053333rem;
  border-radius: 0.053333rem;
  opacity: 1;
}

.company-intro-box-mobile .company-intro-box-intro-message {
  word-wrap: break-all;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F9F9FB));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: -moz-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9FB 100%);
}

.company-intro-box-mobile .company-intro-box-intro-message .title {
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
  text-align: center;
  margin-top: 0.853333rem;
}

.company-intro-box-mobile .company-intro-box-intro-message .details {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.666667rem;
  padding: 0 0.426667rem;
  margin-top: 0.426667rem;
  text-align: justify;
}

.company-intro-box-mobile .company-intro-box-intro-message .details .intro:not(:last-child) {
  margin-bottom: 0.426667rem;
}