@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate360 {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.audio-item-box-mobile {
  width: 3.826667rem;
  height: 0.746667rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.533333rem;
  border-radius: 0.533333rem;
  margin-right: 0.213333rem;
  margin-bottom: 0.213333rem;
}

.audio-item-box-mobile .contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  -webkit-border-radius: 0.533333rem;
  border-radius: 0.533333rem;
  border: 0.026667rem solid #FCC1C5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.106667rem;
}

.audio-item-box-mobile .contain .box-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.audio-item-box-mobile .contain .box-left .playIcon {
  width: 0.533333rem;
  height: 0.533333rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF4B4B), to(#FF8282));
  background: -webkit-linear-gradient(top, #FF4B4B 0%, #FF8282 100%);
  background: -moz-linear-gradient(top, #FF4B4B 0%, #FF8282 100%);
  background: linear-gradient(180deg, #FF4B4B 0%, #FF8282 100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.audio-item-box-mobile .contain .box-left .playIcon i {
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.audio-item-box-mobile .contain .box-left .playIcon .play {
  width: 100%;
  height: 100%;
}

.audio-item-box-mobile .contain .box-left .playIcon .pause {
  width: 100%;
  height: 100%;
}

.audio-item-box-mobile .contain .box-left .playIcon .loading {
  width: 0.4rem;
  height: 0.4rem;
  -webkit-animation: rotate360 1s infinite linear;
  -moz-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
}

.audio-item-box-mobile .contain .box-center {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  margin: 0 0.213333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.audio-item-box-mobile .contain .box-center .stop {
  width: 100%;
}

.audio-item-box-mobile .contain .box-center .audio-progress-contain {
  position: relative;
  width: 100%;
  height: 0.08rem;
  background: rgba(245, 51, 63, 0.3);
  -webkit-border-radius: 0.04rem;
  border-radius: 0.04rem;
}

.audio-item-box-mobile .contain .box-center .audio-progress-contain .audio-progress-touch {
  position: absolute;
  width: 100%;
  left: 0;
  height: 0.453333rem;
  top: -0.2rem;
  z-index: 1;
}

.audio-item-box-mobile .contain .box-center .audio-progress-contain .audio-progress {
  -webkit-border-radius: 0.04rem;
  border-radius: 0.04rem;
  height: 0.066667rem;
  background: #f5333f;
  position: absolute;
  left: 0;
  top: 0;
}

.audio-item-box-mobile .contain .box-center .audio-progress-contain .audio-progress.not-progress {
  -webkit-transition: width 1s linear;
  -moz-transition: width 1s linear;
  transition: width 1s linear;
}

.audio-item-box-mobile .contain .box-center .audio-progress-contain .audio-progress .circle {
  width: 0.32rem;
  height: 0.32rem;
  background: #ffffff;
  position: absolute;
  right: -0.146667rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: -0.106667rem;
  border: 0.026667rem solid #f5333f;
}

.audio-item-box-mobile .contain .box-right {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: bold;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.106667rem 0 0;
}