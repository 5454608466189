.honorary-pan-container {
  margin-top: 32px !important;
  padding: 24px 0;
  width: 1200px;
  height: 544px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 16px 48px 0px rgba(252, 222, 212, 0.42);
          box-shadow: 0px 16px 48px 0px rgba(252, 222, 212, 0.42);
  -webkit-border-radius: 24px 24px 24px 24px;
          border-radius: 24px 24px 24px 24px;
  overflow: auto;
  /* Firefox浏览器 */
  scrollbar-width: none;
}
.honorary-pan-container .liner-pan {
  overflow: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.honorary-pan-container .liner-pan .liner-pan-item {
  width: 300px;
  height: 248px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 300px;
     -moz-box-flex: 0;
      -ms-flex: 0 0 300px;
          flex: 0 0 300px;
  -webkit-animation: liner-pan 16s infinite linear normal;
     -moz-animation: liner-pan 16s infinite linear normal;
          animation: liner-pan 16s infinite linear normal;
  -webkit-animation-play-state: running;
     -moz-animation-play-state: running;
          animation-play-state: running;
}
.honorary-pan-container ::-webkit-scrollbar {
  display: none;
}
@-webkit-keyframes liner-pan {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-2400px);
            transform: translateX(-2400px);
  }
}
@-moz-keyframes liner-pan {
  0% {
    -moz-transform: translateX(0);
         transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-2400px);
         transform: translateX(-2400px);
  }
}
@keyframes liner-pan {
  0% {
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-2400px);
       -moz-transform: translateX(-2400px);
            transform: translateX(-2400px);
  }
}
