.home-training-category-mobile {
  margin-top: 0.64rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 0.853333rem;
  overflow: hidden;
}

.home-training-category-mobile .home-training-category-mobile-scroll {
  overflow-x: auto;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.home-training-category-mobile .home-training-category-mobile-scroll::-webkit-scrollbar {
  display: none;
}

.home-training-category-mobile .category-item {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 4.36rem;
  height: 5rem;
  background: #ffffff;
  -webkit-border-radius: 0.213333rem;
  border-radius: 0.213333rem;
  border: 0.013333rem solid #eeeeee;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.home-training-category-mobile .category-item:first-child {
  margin-left: 0.426667rem;
}

.home-training-category-mobile .category-item:last-child {
  margin-right: 0.426667rem;
}

.home-training-category-mobile .category-item:not(:last-child) {
  margin-right: 0.213333rem;
}

.home-training-category-mobile .category-item .category-item-img {
  width: 100%;
  height: 2.706667rem;
}

.home-training-category-mobile .category-item .category-item-img img {
  width: 100%;
  vertical-align: middle;
}

.home-training-category-mobile .category-item .category-item-message {
  padding: 0.213333rem 0.213333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.home-training-category-mobile .category-item .category-item-message .message-left {
  width: 100%;
}

.home-training-category-mobile .category-item .category-item-message .message-left .category-item-title {
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}

.home-training-category-mobile .category-item .category-item-message .message-left .category-item-tips {
  margin-top: 0.106667rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.44rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-training-category-mobile .category-item .category-item-message .message-right .category-item-price {
  font-size: 0.32rem;
  color: #F5333F;
  line-height: 0.333333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.home-training-category-mobile .category-item .category-item-message .message-right .category-item-price span {
  font-size: 0.186667rem;
  color: #F5333F;
  line-height: 0.266667rem;
  margin-right: 0.026667rem;
}

.home-training-category-mobile .category-item .open-app {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0.213333rem;
  font-size: 0.32rem;
  font-weight: 600;
  color: #F5333F;
  line-height: 0.44rem;
}

.home-training-category-mobile .category-item .open-app i {
  font-size: 0.213333rem;
  margin-left: 0.106667rem;
}