.news-information-box {
  margin: 0 auto !important;
  overflow: hidden;
  padding: 37px 0 64px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper {
  width: 100%;
  height: 100%;
  overflow: unset;
  overflow-x: unset;
  overflow-y: unset;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-container {
  width: 100%;
  height: 100%;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-button-prev,
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-button-next {
  width: 48px;
  height: 48px;
  background: #ddd;
  color: #fff;
  -webkit-border-radius: 50%;
          border-radius: 50%;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-button-prev::after,
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-button-next {
  right: 0px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-button-prev {
  left: 0px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-slide-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .swiper-slide-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item {
  width: 336px;
  height: 430px;
  background: #FFFFFF;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  border: 1px solid #EEEEEE;
  position: relative;
  padding: 48px 24px 24px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item:hover {
  -webkit-transform: translateY(-20px);
     -moz-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item:hover .crrow {
  opacity: 1;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .crrow {
  width: 24px;
  height: 24px;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0px 8px 15px 0px rgba(245, 51, 63, 0.2);
          box-shadow: 0px 8px 15px 0px rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 50%;
          border-radius: 50%;
  position: absolute;
  right: 24px;
  bottom: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  opacity: 0;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .crrow i {
  color: #fff;
  font-size: 12px;
  margin-left: 2px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .title {
  height: 56px;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .details {
  margin-top: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .details .details-message {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 190px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .user-time {
  border-top: 1px solid #EEEEEE;
  padding-top: 23px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .user-time .header-img {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .user-time .name-time {
  margin-left: 12px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .user-time .name-time .name {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.news-information-box .news-information-box-swiper-box .news-information-box-swiper .news-information-item .user-time .name-time .time {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
