.home-brand-report-container {
  width: 1200px;
  margin: 0 auto;
}
.home-brand-report-container .brand-report-list {
  position: relative;
  width: 100%;
  height: 488px;
}
.home-brand-report-container .brand-report-list .brand-report-prev {
  position: absolute;
  top: 50%;
  left: -72px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.home-brand-report-container .brand-report-list .brand-report-prev .to-left {
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
}
.home-brand-report-container .brand-report-list .brand-report-next {
  position: absolute;
  top: 50%;
  right: -72px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.home-brand-report-container .brand-report-list .brand-report-next > i {
  font-size: 20px;
}
.home-brand-report-container .brand-report-list .brand-report-prev,
.home-brand-report-container .brand-report-list .brand-report-next {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(252, 222, 212, 0.42);
          box-shadow: 0px 2px 4px 0px rgba(252, 222, 212, 0.42);
  border: 1px solid #EEEEEE;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  font-size: 20px;
  color: #808080;
  cursor: pointer;
}
.home-brand-report-container .brand-report-list .brand-report-prev > i,
.home-brand-report-container .brand-report-list .brand-report-next > i {
  font-size: 20px;
}
.home-brand-report-container .brand-report-list .brand-report-prev:hover,
.home-brand-report-container .brand-report-list .brand-report-next:hover {
  border: 1px solid #F5333F;
  color: #F5333F;
}
.home-brand-report-container .brand-report-list .brand-report-swiper {
  padding-top: 48px;
  height: 100%;
  width: 100%;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide {
  width: 368px;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item {
  width: 368px;
  height: 440px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item .view-detail-text {
  position: absolute;
  top: 384px;
  right: 36px;
  font-weight: 400;
  font-size: 16px;
  color: #F5333F;
  line-height: 24px;
  text-align: left;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item .report-item-wrap {
  background: url(https://img.qlchat.com/qlLive/activity/image/J3QKXUYL-CYYG-337S-1710140511814-KJO6LURBLHRS.png?x-oss-process=image/format,webp) center / 100% 100% no-repeat;
  -webkit-border-radius: 24px;
          border-radius: 24px;
  padding: 24px 32px;
  height: 100%;
  cursor: pointer;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item .channel-img {
  width: auto;
  height: 64px;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item .title {
  margin-top: 16px;
  font-weight: 500;
  font-size: 20px;
  color: #1A1A1A;
  line-height: 32px;
  text-align: left;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item .content {
  margin: 16px 0;
  font-weight: 400;
  font-size: 16px;
  color: #4D4D4D;
  line-height: 24px;
  text-align: left;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item:hover {
  -webkit-transform: translateY(-16px);
     -moz-transform: translateY(-16px);
          transform: translateY(-16px);
  z-index: 9;
}
.home-brand-report-container .brand-report-list .brand-report-swiper .brand-report-slide .brand-report-item:hover .title {
  color: #F5333F;
}
