.ql-feat-picture {
  font-size: 0;
}

.ql-feat-picture img {
  width: 100%;
  height: 100%;
}

.picture-placeholder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url(//img.qlchat.com/qlLive/liveCommon/picture-loading-icon.png?x-oss-process=image/format,webp) #efefef no-repeat center / 0.746667rem;
}