.introduction-container-mobile {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  padding: 0.32rem;
}

.introduction-container-mobile .introduction-title {
  padding-bottom: 0.16rem;
  font-size: 0.586667rem;
  line-height: 0.666667rem;
  font-weight: bold;
  text-align: center;
}

.introduction-container-mobile .introduction-secondary-title {
  padding: 0.213333rem 0;
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
}

.introduction-container-mobile .introduction-third-title {
  font-size: 0.373333rem;
  font-weight: bold;
  color: #333333;
  line-height: 0.666667rem;
}

.introduction-container-mobile .introduction-content {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.666667rem;
}