.home-community-mobile {
  margin-top: 0.64rem;
  margin-bottom: 0.853333rem;
}

.home-community-mobile .list-box .home-training-clock-in-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.48rem;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0.426667rem;
}

.home-community-mobile .list-box .home-training-clock-in-list .clock-in-item-mobile {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 0.426667rem;
}

.home-community-mobile .list-box .home-training-clock-in-list .clock-in-item-mobile:nth-child(2n) {
  margin-left: 0.186667rem;
}

.home-community-mobile .list-box .more-community-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.346667rem;
  font-weight: 400;
  color: #FFFFFF;
  margin: 0 auto 0;
  width: 3.093333rem;
  height: 0.853333rem;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0 0.133333rem 0.266667rem 0 rgba(245, 51, 63, 0.2);
  box-shadow: 0 0.133333rem 0.266667rem 0 rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
}

.home-community-mobile .nodata {
  text-align: center;
}

.home-community-mobile .nodata img {
  width: 3.2rem;
  height: 3.2rem;
}

.home-community-mobile .nodata .tips {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.266667rem;
  margin-top: 0.106667rem;
}