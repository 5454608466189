.category-third-screen .tab-switch {
  width: 100%;
  background: none;
  padding: 0 0.426667rem;
  overflow-y: scroll;
  height: 0.853333rem;
  margin-bottom: 0.64rem;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.category-third-screen .tab-switch.tab-switch-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.category-third-screen .tab-switch::-webkit-scrollbar {
  display: none;
}

.category-third-screen .tab-switch .tab-switch-item {
  width: 3.413333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #F8F8FB;
  -webkit-border-radius: 1.2rem;
  border-radius: 1.2rem;
  font-weight: 500;
  font-size: 0.4rem;
  color: #1A1A1A;
  margin-right: 0.213333rem;
}

.category-third-screen .tab-switch .tab-switch-item::after {
  display: none;
}

.category-third-screen .tab-switch .tab-switch-item.active {
  background: #FFEFF0;
  color: #F5333F;
}

.category-third-screen .famous-img-box-swiper .swiper-pagination {
  bottom: 0.8rem;
}

.category-third-screen .famous-img-box-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 0.213333rem;
  height: 0.08rem;
  background: rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 1.08rem;
  border-radius: 1.08rem;
  opacity: 1;
}

.category-third-screen .famous-img-box-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #F5333F;
}

.category-third-screen .famous-img-box-swiper .img-card {
  padding: 0 0.426667rem 0;
}

.category-third-screen .famous-img-box-swiper .img-card img {
  width: 100%;
}

.category-third-screen .swiper-box {
  position: relative;
  padding-bottom: 7.013333rem;
}

.category-third-screen .category-third-screen-text {
  width: -moz-calc(100% - 0.853333rem);
  width: calc(100% - 0.853333rem);
  position: absolute;
  bottom: 0;
  left: 0.426667rem;
  height: 7.573333rem;
  margin-top: -0.56rem;
  padding: 0.64rem;
  z-index: 5;
  overflow: hidden;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.65)), color-stop(20%, #FFFFFF), to(#FFFFFF));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.65) 0%, #FFFFFF 20%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.65) 0%, #FFFFFF 20%, #FFFFFF 100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.65) 0%, #FFFFFF 20%, #FFFFFF 100%);
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  border: 0;
  -webkit-border-image: -webkit-linear-gradient(297deg, #ffffff, rgba(255, 255, 255, 0.12), #ffffff) 1 1;
  -o-border-image: linear-gradient(153deg, #ffffff, rgba(255, 255, 255, 0.12), #ffffff) 1 1;
  border-image: -moz-linear-gradient(297deg, #ffffff, rgba(255, 255, 255, 0.12), #ffffff) 1 1;
  border-image: linear-gradient(153deg, #ffffff, rgba(255, 255, 255, 0.12), #ffffff) 1 1;
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  -webkit-backdrop-filter: blur(0.106667rem);
  backdrop-filter: blur(0.106667rem);
}

.category-third-screen .category-third-screen-text .title {
  font-weight: 500;
  font-size: 0.4rem;
  color: #1A1A1A;
  line-height: 0.64rem;
  margin-bottom: 0.32rem;
}

.category-third-screen .category-third-screen-text .desc {
  font-weight: 400;
  font-size: 0.346667rem;
  color: #4D4D4D;
  line-height: 0.533333rem;
}