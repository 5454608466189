.bottom-float {
  min-width: 1200px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px -6px 16px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px -6px 16px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
  height: 120px;
}
.bottom-float .center-box {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-left: 160px;
}
.bottom-float .center-box .middle {
  margin-left: 24px;
  font-size: 28px;
  line-height: 38px;
  font-weight: bold;
}
.bottom-float .center-box .right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 90px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
}
.bottom-float .center-box .right .float-qrcode-img {
  width: 80px;
  height: 80px;
}
.bottom-float .center-box::before {
  content: '';
  display: inline-block;
  width: 160px;
  height: 158px;
  background: url(//res.qianliaowang.com/res-prod/qlchat-edu-offical-frontend/_next/static/images/bottom-logo-9769d65027b262c031e2e09899dab2bc.png) center bottom no-repeat;
  -webkit-background-size: 100% auto;
          background-size: 100% auto;
  position: absolute;
  left: 0;
  bottom: 0;
}
.bottom-float .btn-float-close {
  position: absolute;
  right: 88px;
  top: 24px;
}
