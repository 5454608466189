.video-dialog-box-neo {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 9999;
}

.video-dialog-box-neo .dialog-contain-neo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80vw;
  height: auto;
}

.video-dialog-box-neo .dialog-contain-neo .video-contain-neo {
  width: 100%;
  /*no*/
  height: 100%;
  /*no*/
  background: #000;
}

.video-dialog-box-neo .dialog-contain-neo .close {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}