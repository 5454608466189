.home-about-page .section {
  margin: 58px auto 50px;
  text-align: center;
  max-width: 1200px;
}
@-webkit-keyframes animateUp {
  0% {
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(9, 0);
            transform: translate(9, 0);
    opacity: 1;
  }
}
@-moz-keyframes animateUp {
  0% {
    -moz-transform: translate(0, 20px);
         transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -moz-transform: translate(9, 0);
         transform: translate(9, 0);
    opacity: 1;
  }
}
@keyframes animateUp {
  0% {
    -webkit-transform: translate(0, 20px);
       -moz-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(9, 0);
       -moz-transform: translate(9, 0);
            transform: translate(9, 0);
    opacity: 1;
  }
}
.home-about-page .animateUp {
  -webkit-animation-name: animateUp;
     -moz-animation-name: animateUp;
          animation-name: animateUp;
  -webkit-animation-duration: 1s;
     -moz-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
     -moz-animation-fill-mode: both;
          animation-fill-mode: both;
}
.home-about-page .intro-ql .intro-title {
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 32px;
}
.home-about-page .intro-ql .intro-lecturer {
  margin-bottom: 80px;
  text-align: left;
}
.home-about-page .intro-ql .intro-lecturer .lecturer-name {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.home-about-page .intro-ql .intro-lecturer .intro-item {
  margin-top: 32px;
}
.home-about-page .intro-ql .intro-lecturer .intro-item-title {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.home-about-page .intro-ql .intro-lecturer .intro-item-contain {
  margin-top: 16px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
.home-about-page .intro-ql .intro-lecturer .intro-item-contain > .desc {
  margin-right: 16px;
}
.home-about-page .section {
  width: 1200px;
}
.home-about-page .section .title {
  position: relative;
  letter-spacing: 3px;
  padding: 30px 0 0 0;
  line-height: 1;
  letter-spacing: 0;
}
.home-about-page .section .title .title-text {
  position: relative;
  display: inline-block;
  color: #333;
  font-size: 32px;
  font-weight: bold;
}
.home-about-page .section .sub-title {
  line-height: 1.5;
  padding: 24px 48px;
  text-align: left;
  position: relative;
}
.home-about-page .section .sub-title::before,
.home-about-page .section .sub-title::after {
  position: absolute;
  width: 24px;
  height: 24px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  content: '';
}
.home-about-page .section .sub-title::before {
  top: 0;
  left: 0;
  background: url('https://img.qlchat.com/qlLive/activity/image/31GMCG7C-D6YT-R3UF-1658461444070-H42B5OGTJ1HZ.png?x-oss-process=image/format,webp') no-repeat left center;
}
.home-about-page .section .sub-title::after {
  bottom: 0;
  right: 0;
  background: url('https://img.qlchat.com/qlLive/activity/image/TLW8CKXS-OAZ6-Z3U9-1658461446216-BIMPHS3RAPTG.png?x-oss-process=image/format,webp') no-repeat left center;
}
.home-about-page .section .sub-title p {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 16px;
}
.home-about-page .section .sub-title p:last-child {
  margin-bottom: 0;
}
.home-about-page .section .sub-title p.bold {
  font-size: 20px;
  font-weight: bold;
}
.home-about-page .section .business {
  margin-top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.home-about-page .section .business.img1 {
  background: url('https://img.qlchat.com/qlLive/activity/image/ODFHGM71-CS3D-QVX1-1652348049088-ESRGVRUPW2BL.png?x-oss-process=image/format,webp') no-repeat center;
  -webkit-background-size: 1200px auto;
          background-size: 1200px auto;
}
.home-about-page .section .business.img1 h4 {
  padding-left: 112px;
  background: url('https://img.qlchat.com/qlLive/activity/image/2XCJE8CC-OOV6-JWC3-1687330261636-ZGKSH46MDTXS.png?x-oss-process=image/format,webp') no-repeat left center;
  -webkit-background-size: 95px 95px;
          background-size: 95px;
}
.home-about-page .section .business.img2 {
  background: url('https://img.qlchat.com/qlLive/activity/image/DV8YFUWE-OE5H-6X16-1687330070686-T1FLPG8JDONE.png?x-oss-process=image/format,webp') no-repeat center;
  -webkit-background-size: 1200px auto;
          background-size: 1200px auto;
}
.home-about-page .section .business.img2 h4 {
  padding-left: 156px;
  background: url('https://img.qlchat.com/qlLive/activity/image/67F9ZG9U-ZWC9-AMC1-1687330826453-P6WDX894AYIQ.png?x-oss-process=image/format,webp') no-repeat left center;
  -webkit-background-size: 138px 138px;
          background-size: 138px;
}
.home-about-page .section .business .common {
  width: 50%;
  height: 100%;
  padding: 48px 64px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.home-about-page .section .business .common h4 {
  margin-bottom: 24px;
  height: 48px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
  color: #333;
}
.home-about-page .section .business .common p {
  font-size: 16px;
  color: #333;
  margin-bottom: 30px;
  text-align: left;
  line-height: 26px;
}
.home-about-page .section .business .common p:last-child {
  margin-bottom: 0;
}
.home-about-page .section .business .common p span {
  color: #F73657;
}
.home-about-page .section .progress {
  margin-top: 40px;
  position: relative;
  padding-top: 24px;
}
.home-about-page .section .progress::before {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-1px);
     -moz-transform: translateX(-1px);
          transform: translateX(-1px);
  width: 2px;
  height: 94.8%;
  background: #EEEEEE;
  content: '';
}
.home-about-page .section .progress .common {
  width: 47%;
  padding-bottom: 24px;
  position: relative;
}
.home-about-page .section .progress .common::before {
  position: absolute;
  width: 16px;
  height: 16px;
  background: url('https://img.qlchat.com/qlLive/activity/image/TAY4Q5DK-3HK3-2NBZ-1658461448230-TW7MH91MOAKA.png?x-oss-process=image/format,webp') no-repeat center;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  content: '';
}
.home-about-page .section .progress .common p {
  font-size: 16px;
  color: #F73657;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 8px;
}
.home-about-page .section .progress .common h5 {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 4px;
}
.home-about-page .section .progress .common span {
  font-size: 14px;
  color: #666;
}
.home-about-page .section .progress .left {
  text-align: right;
}
.home-about-page .section .progress .left::before {
  top: 4px;
  right: -44px;
  content: '';
}
.home-about-page .section .progress .right {
  text-align: left;
  margin-left: 53%;
}
.home-about-page .section .progress .right::before {
  top: 4px;
  left: -44px;
  content: '';
}
.home-about-page .section .honor {
  padding: 0 62px;
}
.home-about-page .section .honor .head {
  width: 848px;
  margin: 40px auto;
}
.home-about-page .section .honor .head img {
  width: 100%;
}
.home-about-page .section .honor .list {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: hidden;
}
.home-about-page .section .honor .list img {
  width: 188px;
  height: 83px;
}
