.idea-item-like-list {
  margin-bottom: 32px;
}
.idea-item-like-list .like-list-contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0 32px 0;
  border-bottom: 1px solid #EEEEEE;
}
.idea-item-like-list .like-list-contain .like-list-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.idea-item-like-list .like-list-contain .like-header-list {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.idea-item-like-list .like-list-contain .like-header-list .like-header-img {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  border: 1px solid #DDDDDD;
  overflow: hidden;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}
.idea-item-like-list .like-list-contain .like-header-list .like-header-img img {
  width: 64px;
  height: 64px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
}
.idea-item-like-list .like-list-contain .like-header-list .like-header-img:not(:first-child) {
  margin-left: -16px;
}
.idea-item-like-list .like-list-contain .like-number {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-left: 24px;
}
