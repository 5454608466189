.student-story-box-mobile {
  margin-bottom: 0.853333rem;
  margin-top: 0.64rem;
}

.student-story-box-mobile .student-list .student-item {
  width: 9.146667rem;
  height: 2.786667rem;
  -webkit-border-radius: 0.266667rem;
  border-radius: 0.266667rem;
  overflow: hidden;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/TODM7QK1-PP8G-IH3G-1659686209145-NECSSOZUXAHZ.png?x-oss-process=image/format,webp");
  -webkit-background-size: 100% 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0.533333rem 0.426667rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto 0.426667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.student-story-box-mobile .student-list .student-item .user-header-img {
  width: 1.173333rem;
  height: 1.173333rem;
  -webkit-box-shadow: 0 0.133333rem 0.266667rem 0 rgba(255, 91, 91, 0.3);
  box-shadow: 0 0.133333rem 0.266667rem 0 rgba(255, 91, 91, 0.3);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 0.32rem;
}

.student-story-box-mobile .student-list .student-item .user-message {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.student-story-box-mobile .student-list .student-item .user-message .message {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.533333rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.student-story-box-mobile .student-list .student-item .user-message .user-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.213333rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.44rem;
}

.student-story-box-mobile .student-list .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.student-story-box-mobile .student-list .swiper-pagination-bullet {
  background: #bbbbbb;
}

.student-story-box-mobile .student-list .swiper-pagination-bullet-active {
  width: 0.24rem;
  height: 0.106667rem;
  background: #F5333F;
  -webkit-border-radius: 0.053333rem;
  border-radius: 0.053333rem;
}