.idea-item-user-info-mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.idea-item-user-info-mobile .info-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.idea-item-user-info-mobile .info-left .info-header-img {
  width: 1.066667rem;
  height: 1.066667rem;
  overflow: hidden;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 0.013333rem solid #ddd;
}

.idea-item-user-info-mobile .info-left .info-name-other {
  margin-left: 0.213333rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.idea-item-user-info-mobile .info-left .info-name-other .name-wrap {
  font-size: 0.373333rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.533333rem;
}

.idea-item-user-info-mobile .info-left .info-name-other .time-number {
  font-size: 0.32rem;
  margin-top: 0.093333rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.44rem;
}