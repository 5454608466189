.img-text-content-mobile {
  padding: 0.426667rem 0;
}

.img-text-content-mobile .img-text-content-swiper .swiper-img-box {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.img-text-content-mobile .img-text-content-swiper .swiper-img-box .img-swiper-slide-image {
  overflow: hidden;
  width: 100%;
}

.img-text-content-mobile .img-text-content-swiper .swiper-img-box .img-swiper-slide-image.min-width img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.img-text-content-mobile .img-text-content-swiper .swiper-img-box .img-swiper-slide-image.min-height img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.img-text-content-mobile .img-text-content-swiper img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.img-text-content-mobile .img-text-content-swiper-box {
  position: relative;
  margin: 0 -0.426667rem;
}

.img-text-content-mobile .img-text-content-swiper-box .swiper-page-num {
  height: 0.426667rem;
  padding: 0 0.12rem;
  background: #000000;
  -webkit-border-radius: 0.106667rem;
  border-radius: 0.106667rem;
  opacity: 0.5;
  position: absolute;
  bottom: 0.32rem;
  right: 0.426667rem;
  z-index: 5;
  font-weight: 500;
  font-size: 0.266667rem;
  color: #FFFFFF;
  line-height: 0.426667rem;
  text-align: center;
}

.img-text-content-mobile .img-text-title {
  font-weight: 600;
  font-size: 0.506667rem;
  color: #1A1A1A;
  line-height: 0.853333rem;
  letter-spacing: 0.013333rem;
  text-align: justify;
  margin-top: 0.426667rem;
}

.img-text-content-mobile .img-text-content {
  font-weight: 400;
  font-size: 0.506667rem;
  color: #1A1A1A;
  line-height: 0.853333rem;
  letter-spacing: 0.013333rem;
  text-align: left;
  margin: 0.213333rem 0 0.32rem;
}

.img-text-content-mobile .img-text-content span {
  color: #2B63D2;
  margin-right: 0.106667rem;
}

.img-text-content-mobile .img-text-time {
  font-weight: 400;
  font-size: 0.346667rem;
  color: #808080;
  line-height: 0.413333rem;
  text-align: left;
}