.home-container-mobile {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.home-container-mobile .fadeInUp {
  -webkit-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
  -moz-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
  animation: fadeInUp 0.3s ease-in-out 0s 1 both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.426667rem, 0);
    -moz-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

.home-container-mobile .main-container .main-container-bg {
  background-color: #fff;
}

.home-container-mobile .main-container .main-container-bg.bg1 {
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F9F9FB));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: -moz-linear-gradient(top, #FFFFFF 0%, #F9F9FB 100%);
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9FB 100%);
}

.home-container-mobile .main-container .main-container-bg.bg2 {
  background: #f9f9fb;
}

.home-container-mobile .main-container .main-container-bg.bg3 {
  background: -webkit-gradient(linear, left top, left bottom, from(#F9F9FB), to(#FFFFFF));
  background: -webkit-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, #F9F9FB 0%, #FFFFFF 100%);
}

.home-container-mobile .main-container .main-container-bg.second-bg {
  background: url(https://img.qlchat.com/qlLive/activity/image/LPZ2T8JM-QKK2-6FJT-1709104134275-NE8CCCAUID86.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  padding: 1.173333rem 0.426667rem 0;
}

.home-container-mobile .main-container .main-container-bg.third-bg {
  background: url(https://img.qlchat.com/qlLive/activity/image/TWLFK7UG-426D-1NQ3-1710153953085-LYKAIXN5ENPM.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  padding: 1.066667rem 0.426667rem 0.906667rem;
}

.home-container-mobile .main-container .main-container-bg.fourth-bg {
  background: url(https://img.qlchat.com/qlLive/activity/image/DJ9KQYNY-Z87G-8RLH-1710153950630-KKIDU77AEC8F.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  padding: 1.333333rem 0 0;
}

.home-container-mobile .main-container .home-training-grey-bg-about {
  position: relative;
  height: 8.466667rem;
}

.home-container-mobile .main-container .home-training-grey-bg-about .home-training-title {
  margin-top: 0;
  padding-top: 0.853333rem;
}

.home-container-mobile .main-container .home-training-grey-bg-about .about-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8.466667rem;
}

.home-container-mobile .main-container .home-training-grey-bg-about .about-contain {
  position: relative;
}

.home-container-mobile .main-container .home-training-title {
  padding-top: 0.853333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.home-container-mobile .main-container .home-training-title img {
  display: block;
  height: 0.733333rem;
}

.home-container-mobile .main-container .home-training-title.big img {
  height: 1.333333rem;
}

.home-container-mobile .main-container .home-training-new-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.home-container-mobile .main-container .home-training-new-title img {
  height: 0.733333rem;
}

.home-container-mobile .main-container .home-training-content {
  padding: 0.426667rem 0 0.64rem;
}

.home-container-mobile .main-container .home-training-content p {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #4D4D4D;
  line-height: 0.586667rem;
}