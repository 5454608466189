.user-is-teacher-mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  margin-left: 0.24rem;
  z-index: 0;
  white-space: nowrap;
}

.user-is-teacher-mobile img {
  display: block;
  width: 0.373333rem;
  height: 0.4rem;
  position: absolute;
  left: -0.186667rem;
  z-index: 1;
}

.user-is-teacher-mobile span {
  display: block;
  padding: 0 0.106667rem 0 0.266667rem;
  height: 0.373333rem;
  background: #FFEFEC;
  -webkit-border-radius: 0 1.333333rem 1.333333rem 0;
  border-radius: 0 1.333333rem 1.333333rem 0;
  left: 0.213333rem;
  text-align: right;
  font-size: 0.266667rem;
  font-weight: 400;
  color: #F5333F;
  line-height: 0.373333rem;
  padding-right: 0.106667rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}