.clock-in-item {
  width: 221px;
  height: 409px;
  background: #FFFFFF;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  border: 1px solid #EEEEEE;
  overflow: hidden;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
}
.clock-in-item:hover {
  -webkit-transform: translateY(-20px);
     -moz-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
}
.clock-in-item .clock-img-box {
  position: relative;
  height: 293px;
  width: 100%;
  overflow: hidden;
}
.clock-in-item .clock-img-box img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.clock-in-item .clock-img-box .video-play-cion {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  right: 16px;
  display: block;
  background-image: url("https://img.qlchat.com/qlLive/activity/image/BE3ODLGM-YPY9-H7FF-1653387966274-43XXRM41MGUH.png?x-oss-process=image/format,webp");
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
}
.clock-in-item .content-message {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 16px;
  margin: 16px 0;
  min-height: 44px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  word-break: break-all;
}
.clock-in-item .user-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
}
.clock-in-item .user-message .user-header-img {
  width: 24px;
  height: 24px;
  border: 1px solid #DDDDDD;
  overflow: hidden;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  margin-right: 8px;
}
.clock-in-item .user-message .user-header-img img {
  width: 100%;
  height: 100%;
}
.clock-in-item .user-message .user-name {
  width: 120px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
