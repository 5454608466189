.left-nav-mobile {
  position: fixed;
  bottom: 0.64rem;
  z-index: 9;
  overflow: hidden;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 8.72rem;
  height: 1.493333rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0.053333rem 0.32rem 0.026667rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.053333rem 0.32rem 0.026667rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 2.026667rem;
  border-radius: 2.026667rem;
}

.left-nav-mobile .nav-list {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.213333rem 0 0.853333rem;
}

.left-nav-mobile .nav-list .nav-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.left-nav-mobile .nav-list .nav-list-item img {
  width: 0.533333rem;
  height: 0.533333rem;
}

.left-nav-mobile .nav-list .nav-list-item span {
  font-weight: 400;
  font-size: 0.346667rem;
  color: #1A1A1A;
  line-height: 0.48rem;
  margin-top: 0.053333rem;
}

.left-nav-mobile .nav-list .nav-list-item.btn {
  width: 3.813333rem;
  height: 1.066667rem;
  background: #FFFFFF;
  -webkit-border-radius: 1.786667rem;
  border-radius: 1.786667rem;
  border: 0.013333rem solid #F5333F;
  font-size: 0.426667rem;
  color: #F5333F;
  text-align: center;
  line-height: 1.066667rem;
}

.left-nav-mobile .nav-list.top {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}