.course-detail-page-wrap {
  width: 640px;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #fff;
  padding-bottom: 120px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.course-detail-page-wrap .img-wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.course-detail-page-wrap .img-wrap > img {
  width: 100%;
  display: block;
}
.course-detail-page-wrap .pay-btn-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 120px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
}
.course-detail-page-wrap .pay-btn-wrap .pay-btn {
  width: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 96px;
  background: -webkit-linear-gradient(225deg, #FF9F33 0%, #FF6033 100%);
  background: -moz-linear-gradient(225deg, #FF9F33 0%, #FF6033 100%);
  background: linear-gradient(225deg, #FF9F33 0%, #FF6033 100%);
  -webkit-border-radius: 52px;
          border-radius: 52px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: normal;
  color: #fff;
  font-weight: bold;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.course-detail-page-wrap .pay-btn-wrap .pay-btn .unit {
  font-size: 32px;
}
.course-detail-page-wrap .pay-btn-wrap .pay-btn .num {
  font-size: 48px;
}
.course-detail-page-wrap .pay-btn-wrap .pay-btn .text {
  font-size: 36px;
  margin-left: 8px;
}
.course-detail-page-wrap .bottom-dialog {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}
.course-detail-page-wrap .bottom-dialog.hide {
  display: none;
}
.course-detail-page-wrap .bottom-dialog .mask-bg {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.course-detail-page-wrap .bottom-dialog .content-box {
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-border-radius: 32px 32px 0px 0px;
          border-radius: 32px 32px 0px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 2;
}
.course-detail-page-wrap .bottom-dialog .content-box .title-wrap {
  width: 100%;
  height: 96px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: normal;
  font-size: 32px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
     -moz-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
.course-detail-page-wrap .bottom-dialog .content-box .close-img {
  width: 48px;
  position: absolute;
  top: 24px;
  right: 32px;
  cursor: pointer;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn {
  height: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  overflow-y: auto;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 32px;
  height: 120px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box .icon {
  width: 64px;
  margin-right: 24px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
     -moz-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box .data-wrap {
  width: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box .data-wrap .name {
  font-size: 28px;
  line-height: 40px;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box .data-wrap .value {
  font-size: 24px;
  color: #999;
  margin-top: 8px;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box .iconfont-licai {
  -webkit-box-flex: 0;
  -webkit-flex: none;
     -moz-box-flex: 0;
      -ms-flex: none;
          flex: none;
  font-size: 32px;
  color: #FF790D;
}
.course-detail-page-wrap .bottom-dialog .content-box .content-ctn .item-box .un-select-icon {
  width: 32px;
  height: 32px;
  border: 1px solid #BBBBBB;
  -webkit-border-radius: 50%;
          border-radius: 50%;
}
.course-detail-page-wrap .bottom-dialog .content-box .dialog-pay-btn-wrap {
  width: 100%;
  height: 120px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
  -webkit-box-shadow: inset 0px 1px 0px 0px #EEEEEE;
          box-shadow: inset 0px 1px 0px 0px #EEEEEE;
}
.course-detail-page-wrap .bottom-dialog .content-box .dialog-pay-btn-wrap .dialog-pay-btn {
  width: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 96px;
  background: -webkit-linear-gradient(225deg, #FF9F33 0%, #FF6033 100%);
  background: -moz-linear-gradient(225deg, #FF9F33 0%, #FF6033 100%);
  background: linear-gradient(225deg, #FF9F33 0%, #FF6033 100%);
  -webkit-border-radius: 52px;
          border-radius: 52px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: normal;
  color: #fff;
  font-weight: bold;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-size: 32px;
}
