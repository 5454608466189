.home-community {
  margin-top: 36px !important;
}
.home-community .list-box .home-training-clock-in-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 36px;
}
.home-community .list-box .home-training-clock-in-list .clock-in-item:not(:last-child) {
  margin-right: 23px;
}
.home-community .list-box .more-community-btn {
  width: 192px;
  height: 48px;
  background: -webkit-gradient(linear, left top, right top, from(#FF9678), to(#F5333F));
  background: -webkit-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: -moz-linear-gradient(left, #FF9678 0%, #F5333F 100%);
  background: linear-gradient(90deg, #FF9678 0%, #F5333F 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(245, 51, 63, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(245, 51, 63, 0.2);
  -webkit-border-radius: 24px;
          border-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  margin: 32px auto 0;
}
.home-community .nodata {
  text-align: center;
}
.home-community .nodata img {
  width: 120px;
  height: 120px;
}
.home-community .nodata .tips {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-top: 8px;
}
