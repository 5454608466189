.co-image-viewer {
  width: 100% !important;
}

[data-dpr="1"] .co-image-viewer {
  max-width: 640px;
  min-width: 320px;
}

[data-dpr="2"] .co-image-viewer {
  max-width: 1280px;
  min-width: 640px;
}

[data-dpr="3"] .co-image-viewer {
  max-width: 1920px;
  min-width: 960px;
}

.co-image-viewer {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 9.133333rem;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  overflow: hidden;
}

.co-image-viewer.hidden {
  display: none;
}

.co-image-viewer .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 1001;
}

.co-image-viewer .close-btn {
  position: absolute;
  z-index: 1003;
  right: 0.4rem;
  top: 0.4rem;
  width: 0.906667rem;
  height: 0.906667rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  background: url('https://img.qlchat.com/qlLive/activity/image/2SDK2X6I-MWCE-UNFL-1588059488451-X4GL65DA3R9I.png?x-oss-process=image/format,webp') no-repeat center center;
  -webkit-background-size: 100% 100%;
  background-size: 100%;
}

.co-image-viewer img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition-property: margin,width,height;
  -moz-transition-property: margin,width,height;
  transition-property: margin,width,height;
  z-index: 1002;
}