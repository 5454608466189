.more-courses-details-mobile {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.more-courses-details-mobile .details-img {
  width: 100%;
}

.more-courses-details-mobile .details-content {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.6rem 0.64rem 0;
  -webkit-border-radius: 0.426667rem 0.426667rem 0 0;
  border-radius: 0.426667rem 0.426667rem 0 0;
  background: #fff;
  z-index: 5;
}

.more-courses-details-mobile .details-content .title {
  font-weight: 500;
  font-size: 0.506667rem;
  color: #1A1A1A;
  line-height: 0.853333rem;
  text-align: left;
}

.more-courses-details-mobile .details-content .label {
  font-weight: 500;
  font-size: 0.426667rem;
  color: #F5333F;
  line-height: 0.693333rem;
}

.more-courses-details-mobile .details-content .desc-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 0.32rem;
  margin-bottom: 0.426667rem;
}

.more-courses-details-mobile .details-content .desc-list .desc {
  padding: 0.186667rem 0.32rem;
  background: #F7F3F3;
  -webkit-border-radius: 0.16rem;
  border-radius: 0.16rem;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #1A1A1A;
  line-height: 0.586667rem;
  margin-right: 0.213333rem;
}

.more-courses-details-mobile .details-content .nav-list {
  height: 1.96rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 0.013333rem solid #DCDFE6;
}

.more-courses-details-mobile .details-content .nav-list .nav-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.more-courses-details-mobile .details-content .nav-list .nav-list-item img {
  width: 0.533333rem;
  height: 0.533333rem;
}

.more-courses-details-mobile .details-content .nav-list .nav-list-item span {
  font-weight: 400;
  font-size: 0.346667rem;
  color: #1A1A1A;
  line-height: 0.48rem;
  margin-top: 0.053333rem;
}

.more-courses-details-mobile .details-content .nav-list .nav-list-item.btn {
  width: 4.773333rem;
  height: 1.093333rem;
  background: #FFFFFF;
  -webkit-border-radius: 1.786667rem;
  border-radius: 1.786667rem;
  background: #F5333F;
  font-size: 0.426667rem;
  color: #fff;
  text-align: center;
  line-height: 1.066667rem;
}

.more-courses-details-mobile .details-content .nav-list.top {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.more-courses-details-mobile .details-bottom {
  height: 5.466667rem;
}