.comunity-details-page-mobile {
  background-color: #fff;
  padding-top: 1.493333rem;
  padding-bottom: 2.133333rem;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
}

.comunity-details-page-mobile .top-menu .top-menu-header {
  border-bottom: 0.013333rem solid #eeeeee;
}

.comunity-details-page-mobile .main-container {
  padding: 0.426667rem 0.426667rem 0;
}

.comunity-details-page-mobile .no-data-box {
  text-align: center;
  margin: 2.2rem auto;
}

.comunity-details-page-mobile .no-data-box img {
  width: 3.2rem;
  height: 3.2rem;
}

.comunity-details-page-mobile .no-data-box .tips {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.266667rem;
  margin-top: 0.346667rem;
}

.comunity-details-page-mobile .space {
  height: 0.16rem;
  background: #F7F7F7;
  margin: 0 -0.426667rem;
}