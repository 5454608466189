.about-ql-edu-container {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.about-ql-edu-container .main-container .main-container-bg {
  background-color: #fff;
  overflow: hidden;
}

.about-ql-edu-container .main-container .main-container-bg .cooperation-img {
  margin-top: 0.373333rem;
  width: 100%;
  margin-bottom: 0.28rem;
}

.about-ql-edu-container .main-container .main-container-bg-honorary {
  overflow: hidden;
  background: -webkit-gradient(linear, left top, left bottom, from(#F9F9FB), to(#FFFFFF));
  background: -webkit-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(top, #F9F9FB 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, #F9F9FB 0%, #FFFFFF 100%);
}

.about-ql-edu-container .main-container .home-training-title {
  margin-top: 0.853333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.about-ql-edu-container .main-container .home-training-title img {
  display: block;
  height: 0.733333rem;
}

.about-ql-edu-container .main-container .home-training-title.big img {
  height: 1.333333rem;
}