.video-dialog-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 9999;
}

.video-dialog-box .dialog-contain {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 62.5%;
  height: 62.5%;
  min-width: 1080px;
  min-height: 607.5px;
  margin-top: -48px;
}

.video-dialog-box .dialog-contain .video-contain {
  width: 100%;
  /*no*/
  height: 100%;
  /*no*/
  background: #000;
}

.video-dialog-box .dialog-contain .close {
  margin-top: 48px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}