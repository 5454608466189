.user-is-teacher {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
  margin-left: 18px;
  z-index: 0;
  white-space: nowrap;
}
.user-is-teacher img {
  display: block;
  width: 15px;
  height: 19px;
  position: absolute;
  left: -8px;
  z-index: 1;
}
.user-is-teacher span {
  display: block;
  padding: 0 3px 0 11px;
  height: 16px;
  background: #FFEFEC;
  -webkit-border-radius: 0px 100px 100px 0px;
          border-radius: 0px 100px 100px 0px;
  left: 16px;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  color: #F5333F;
  line-height: 16px;
  padding-right: 8px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
